import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScrollspyContainerDirective } from './scrollspy-container.directive';
import { ScrollspyTarget } from './scrollspy-target';

@Injectable({
  providedIn: 'root',
})
export class ScrollspyService {
  private _scrollTargets: ScrollspyTarget[] = [];
  private scrollspyContainerDirective: ScrollspyContainerDirective;
  private scrollEventSubj = new Subject<unknown>();

  constructor() {}

  get scrollEvents$() {
    return this.scrollEventSubj.asObservable();
  }

  get scrollTargets() {
    return this._scrollTargets;
  }

  find(id: string): ScrollspyTarget {
    return this._scrollTargets.find((st) => {
      return st.id === id;
    });
  }

  scrollTo(id: string, options: { emitEvent: boolean }) {
    this.scrollEventSubj.next({ id, options });
  }

  addTarget(target: ScrollspyTarget) {
    this._scrollTargets.push(target);
  }

  removeTarget(target: ScrollspyTarget) {
    const index = this._scrollTargets.findIndex(({ id }) => id === target.id);
    this._scrollTargets.splice(index, 1);
  }
}
