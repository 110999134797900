import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActiveElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartEvent,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  TooltipItem,
} from 'chart.js';

// for treeshaking we only load what we need and register this (iso loading everything from 'chart.js/auto'),
// cf. https://www.chartjs.org/docs/latest/getting-started/integration.html#bundlers-webpack-rollup-etc
Chart.register(CategoryScale, LinearScale, LineController, PointElement, LineElement, Tooltip, Legend);

@Component({
  selector: 'nxh-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() titleUnit: string;
  @Input() axisTitle?: AxisTitle;
  @Input() legend = false;
  /**
   * Multi-array holding collections of points to be shown as a line in the graph.
   * If datasets is of type CData[] then each dataset also has a label which refers to the name shown in the legend.
   */
  @Input() datasets: CData[] | any[][];
  /**
   * Labels to be displayed along the x-axis
   */
  @Input() labels: string[];
  /**
   * To be displayed tooltips. There is one tooltip per x value so multiple series share the same tooltip
   */
  @Input() tooltips?: ChartTooltip[];
  /**
   * Array of colors specifying the colors of the given datasets
   */
  @Input() colors: ChartColor[] = [
    {
      borderColor: '#51A5DE',
      pointBorderColor: '#51A5DE',
      pointBackgroundColor: 'white',
      pointHoverBorderColor: '#51A5DE',
      pointHoverBackgroundColor: 'white',
      backgroundColor: 'white',
    },
  ];
  /**
   * Array of options specifying properties of the line itself
   */
  @Input() lineOptions: LineOptions = {
    borderWidth: 3,
    fill: false,
    pointRadius: 7,
    pointHitRadius: 8,
    pointBorderWidth: 3,
    pointHoverBorderWidth: 4,
    pointHoverRadius: 8,
  };
  /**
   * Specifies the curvature of the line(s)
   */
  @Input() lineTension = 0;
  /**
   * Specifies wheter the lines are stepped
   */
  @Input() lineStepped = false;
  /**
   * Specifies which or how points are selected on hover, cf. https://www.chartjs.org/docs/latest/configuration/interactions.html#modes
   */
  @Input() interactionMode: 'index' | 'point' | 'dataset' | 'nearest' = 'point';
  /**
   * Specifies which points are shown in the tooltip, e.g. show index to combine all values of the same index
   */
  @Input() tooltipMode: 'index' | 'point' | 'dataset' | 'nearest' = 'point';
  /**
   * Specifies whether the tooltip caret should be visible or not. This is handy in combination with tooltipMode 'index',
   * when the tooltip is relevant to multiple points and sits somewhere in between those points.
   */
  @Input() tooltipHideCaret = false;
  @Output() selectPoint = new EventEmitter<SelectPointEvent>();
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private chart: Chart;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  @Input() set data(data: ChartData | null) {
    this.datasets = data?.datasets ?? [];
    this.tooltips = data?.tooltips;
    this.labels = data?.labels ?? [];
    this.legend = data?.legend;
  }

  ngAfterViewInit(): void {
    const config: ChartConfiguration<'line'> = {
      type: 'line',

      data: { datasets: [] },
      options: {
        elements: { line: { tension: this.lineTension, stepped: this.lineStepped } },
        layout: {
          padding: {
            left: 0,
            right: 5,
            top: 5,
            bottom: 0,
          },
        },
        aspectRatio: 5,
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: this.axisTitle?.xAxis ?? '',
              align: 'end',
            },
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
            },
          },
          y: {
            title: {
              display: true,
              text: this.axisTitle?.yAxis ?? '',
              align: 'end',
            },
            display: true,
            ticks: {
              autoSkip: true,
            },
          },
        },
        onClick: (event: ChartEvent, active: ActiveElement[], chart: Chart) => {
          const points = active.map((el) => ({
            selected: chart.data.datasets[el.datasetIndex][el.index],
            datasetIndex: el.datasetIndex,
            index: el.index,
          }));
          this.selectPoint.emit({ points, srcEvent: event });
        },
        interaction: {
          mode: this.interactionMode,
        },
        plugins: {
          legend: {
            display: this.legend,
            position: 'top',
          },
          tooltip: {
            titleFont: {
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              size: 12,
            },
            footerFont: {
              size: 10,
            },
            borderWidth: 0,
            caretPadding: 16,
            mode: this.tooltipMode,
            intersect: true,
            displayColors: true,
            padding: {
              left: 16,
              right: 16,
              top: 16,
              bottom: 16,
            },
            callbacks: {
              title: (tooltipItems: TooltipItem<'line'>[]): string | string[] => {
                return this.tooltips
                  ? this.convertValue(this.tooltips[tooltipItems[0].dataIndex].title)
                  : tooltipItems[0].label;
              },
              beforeBody: (tooltipItems: TooltipItem<'line'>[]) => {
                return this.tooltips ? this.tooltips[tooltipItems[0].dataIndex].content : '';
              },
              label: (tooltipItem: TooltipItem<'line'>) => {
                //don't show the labels if we have tooltips because we need to hide the icon that is being
                // added automatically before each label
                return this.tooltips ? null : ' ' + tooltipItem.formattedValue;
              },
              footer: (tooltipItems: TooltipItem<'line'>[]) => {
                return this.tooltips ? this.tooltips[tooltipItems[0].dataIndex].footer : '';
              },
            },
          },
        },
      },
    };

    if (this.selectPoint.observers?.length > 0) {
      config.options.onHover = function (e) {
        const points = this.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false);
        if (points.length) {
          e.native.target['style'].cursor = 'pointer';
        } else {
          e.native.target['style'].cursor = 'default';
        }
      };
    }

    if (this.labels) {
      config.data.labels = this.labels;
    }

    if (this.datasets) {
      config.data.datasets = addColorsAndLineOptions(this.datasets, this.lineOptions, this.colors);
    }

    if (this.tooltipHideCaret) {
      config.options.plugins.tooltip.caretSize = 0;
    }

    this.chart = new Chart(this.canvas.nativeElement, config);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.datasets || changes.data) && this.chart) {
      this.chart.config.data.datasets = addColorsAndLineOptions(this.datasets, this.lineOptions, this.colors);
      this.chart.config.data.labels = this.labels;
      this.chart.update();
    }
  }

  private convertValue(val) {
    if (!isNaN(val)) {
      return formatNumber(val, this.locale);
    }
    return val;
  }
}

function addColorsAndLineOptions(datasets: CData[] | any[][], options: LineOptions, colors) {
  return datasets.map((dataset, index) => {
    const data = isCData(dataset) ? dataset.data : dataset;
    const label = isCData(dataset) ? dataset.label : undefined;
    return {
      data: data,
      label: label,
      borderWidth: options.borderWidth,
      fill: options.fill,
      pointRadius: options.pointRadius,
      pointHitRadius: options.pointHitRadius,
      pointBorderWidth: options.borderWidth,
      pointHoverBorderWidth: options.pointHoverBorderWidth,
      pointHoverRadius: options.pointHoverRadius,
      ...colors[index],
    };
  });
}

export function isCData(data: CData | any[]): data is CData {
  return (data as CData).label !== undefined;
}

export interface ChartData {
  datasets: CData[] | any[][];
  labels: string[];
  tooltips?: ChartTooltip[];
  legend?: boolean;
}

export interface ChartTooltip {
  title: string;
  content: string;
  footer: string;
}

export interface SelectPointEvent {
  points: {
    selected: any;
    datasetIndex: number;
    index: number;
  }[];
  srcEvent: ChartEvent;
}

export interface CData {
  data: any[];
  label: string;
}

export interface ChartColor {
  borderColor: string;
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointHoverBorderColor: string;
  pointHoverBackgroundColor: string;
  backgroundColor?: string;
}

export interface LineOptions {
  borderWidth?: number;
  fill?: boolean;
  pointRadius?: number;
  pointHitRadius?: number;
  pointBorderWidth?: number;
  pointHoverBorderWidth?: number;
  pointHoverRadius?: number;
}

export interface AxisTitle {
  xAxis: string;
  yAxis: string;
}
