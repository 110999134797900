<div [formGroup]="form" nxhToggle [pills]="pills">
  <ng-container *ngFor="let option of options; let index = index">
    <input
      type="checkbox"
      class="btn-check"
      [nxhDataTest]="'multi-toggle-list-item-' + index"
      [formControlName]="$any(option.value)"
      [id]="id + '_' + index"
      autocomplete="off"
    />
    <label
      class="btn btn-primary"
      nxhToggleOption
      [showCheckmark]="showCheckMarks"
      [class.active]="form.get($any(option.value)).value"
      [class.disabled]="form.get($any(option.value)).disabled"
      [for]="id + '_' + index"
    >
      <ng-container *ngIf="optionDirective?.template; else noTemplate">
        <ng-container *ngTemplateOutlet="optionDirective.template; context: { $implicit: option }"></ng-container>
      </ng-container>
      <ng-template #noTemplate>
        <span>{{ option.label | i18next }}</span>
      </ng-template>
    </label>
  </ng-container>
</div>
