import { ComponentType } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverComponent, PopoverRef } from '@nexuzhealth/shared-ui-toolkit/popover';
import { TYPEAHEAD_CONFIG, TypeaheadConfig } from './typeahead-config.model';
import { TYPEAHEAD_OPTION_CONFIG, TypeaheadOptionComponent } from './typeahead-option.component';
import { TypeaheadService } from './typeahead.service';

@Component({
  template: `
    <div
      [ngSwitch]="!!customTemplateRef"
      class="typeahead-popover--container"
      [class.cdk-overlay-container--hidden]="!showNoResult && (typeaheadService.options$ | async)?.length === 0"
      [class.scrollable]="isScrollable"
    >
      <ng-container *ngSwitchCase="true">
        <ng-container
          *ngTemplateOutlet="
            customTemplateRef;
            context: {
              $implicit: (typeaheadService.options$ | async),
              options: (typeaheadService.options$ | async),
              term: typeaheadService.term$ | async,
              active: (typeaheadService.active$ | async),
              completed: (typeaheadService.completed$ | async),
              select: selectOption.bind(this),
              createListItemId: createListItemId,
              allResults: allResultsOptions.bind(this),
            }
          "
        ></ng-container>
      </ng-container>
      <div class="typeahead-popover--options" *ngSwitchDefault>
        <ul class="list-inline">
          <li
            #listItem
            *ngFor="let option of typeaheadService.options$ | async; let index = index"
            class="typeahead-popover--option"
            [class.active]="index === (typeaheadService.active$ | async)"
            [attr.id]="createListItemId(index)"
            (click)="selectOption(option)"
          >
            <ng-container [ngSwitch]="!!optionTemplateRef">
              <ng-container *ngSwitchCase="true">
                <ng-container
                  *ngTemplateOutlet="
                    optionTemplateRef;
                    context: {
                      $implicit: option,
                      term: typeaheadService.term$ | async,
                      active: index === (typeaheadService.active$ | async),
                    }
                  "
                ></ng-container>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <ng-container
                  *ngComponentOutlet="
                    optionComponentRef;
                    injector: createInjector(option, index === (typeaheadService.active$ | async))
                  "
                ></ng-container>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="showNoResult && (typeaheadService.options$ | async)?.length === 0">
      <ng-container [ngSwitch]="!!noResultTemplateRef">
        <ng-container *ngSwitchCase="true">
          <ng-container
            *ngTemplateOutlet="
              noResultTemplateRef;
              context: {
                $implicit: typeaheadService.term$ | async,
                completed: (typeaheadService.completed$ | async),
                advancedSearch: advancedSearchingOptions.bind(this),
              }
            "
          >
          </ng-container>
        </ng-container>
        <div class="bg-white shadow w-100 rounded-bottom pb-1 pt-1" *ngSwitchDefault>
          <div
            class="p-2 font-italic"
            [innerHTML]="'no-results%term' | i18next: { term: typeaheadService.term$ | async }"
          ></div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./typeahead-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeaheadPopoverComponent<T> implements PopoverComponent, AfterViewInit, OnDestroy {
  /**
   * TemplateRef for displaying a list option. Note that optionTemplateRef has precedence over optionComponentRef.
   */
  optionTemplateRef: TemplateRef<any>;

  /**
   * ComponentRef for displaying a list option. Defaults to AutosuggestOptionComponent.
   */
  optionComponentRef: ComponentType<any> = TypeaheadOptionComponent;

  /**
   * By default the options are displayed as an unordered list. Provide a customTemplateRef when you want something else.
   */
  customTemplateRef: TemplateRef<any>;

  /**
   * Denotes wheter we want to show a message when no results can be found
   */
  _showNoResult = false;

  /**
   * TemplateRef for displaying a custom 'nothing found' component.
   */
  noResultTemplateRef: TemplateRef<any>;

  // /**
  //  * TemplateRef for displaying an initial message on first focus.
  //  */
  // focusTemplateRef: TemplateRef<any>;

  // onFocus = true;

  private destroy = new Subject<void>();

  constructor(
    private popoverRef: PopoverRef,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    @Inject(TYPEAHEAD_CONFIG) private typeaheadConfig: TypeaheadConfig,
    public typeaheadService: TypeaheadService<T>,
  ) {
    Object.assign(this, typeaheadConfig);
  }

  get isScrollable() {
    return this.typeaheadConfig.scrollable;
  }

  get showNoResult() {
    return this._showNoResult || !!this.noResultTemplateRef;
  }

  set showNoResult(showNoResult) {
    this._showNoResult = showNoResult;
  }

  // get showFocus() {
  //   return this.onFocus && !!this.focusTemplateRef && !this.term;
  // }

  createInjector(option, index) {
    const active = index === this.typeaheadService.getActive();
    const term = this.typeaheadService.getTerm();
    return Injector.create({
      providers: [{ provide: TYPEAHEAD_OPTION_CONFIG, useValue: { option, active, term } }],
      parent: this.injector,
    });
  }

  selectOption(option) {
    this.typeaheadService.selectOption(option);
  }

  allResultsOptions() {
    this.typeaheadService.allResultsOptions();
  }

  advancedSearchingOptions() {
    this.typeaheadService.advancedSearchingOptions();
  }

  ngAfterViewInit(): void {
    this.typeaheadService.active$.pipe(takeUntil(this.destroy)).subscribe((active) => {
      // note: typically we would do this using ViewChildren, e.g.
      // @ViewChildren('listItem') private listItems: QueryList<ElementRef>;
      // const activeListItem = this.listItems.find((item, index) => index === active);)
      // However, this is not possible when passing a ResultTemplate to the TypeaheadDirective,
      // so we do it using createOptionId
      const activeListItem = document.getElementById(this.createListItemId(active));
      if (activeListItem) {
        activeListItem.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    });
  }

  createListItemId(index) {
    return `option-${index}`;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
