<div class="form-group">
  <div class="time-picker__inputbox">
    <input
      #ref
      placeholder="00:00"
      class="form-control time-picker__input"
      [id]="id"
      [class.compact]="compact"
      type="time"
      [step]="step"
      [max]="max"
      [min]="min"
      [label]="label | i18next"
      nxhCompactFormControl=""
      [nxhControlError]="errorMessage"
      [required]="required"
      [autofocus]="autofocus"
      [autocomplete]="autocomplete"
      (change)="valueChange($event)"
      (blur)="blur()"
      value="00:00"
    />
  </div>
</div>
