import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CollapseService {
  private readonly internalIsOpen$ = new BehaviorSubject(false);
  private internalDisabled = false;

  readonly isOpen$ = this.internalIsOpen$.asObservable();
  readonly state$ = this.internalIsOpen$.pipe(map(this.getStateValue));

  get isOpen() {
    return this.internalIsOpen$.getValue();
  }

  get state() {
    return this.getStateValue(this.isOpen);
  }

  get disabled() {
    return this.internalDisabled;
  }

  constructor() {}

  toggle() {
    if (!this.internalDisabled) {
      this.internalIsOpen$.next(!this.isOpen);
    }
  }

  open() {
    if (!this.internalDisabled) {
      this.internalIsOpen$.next(true);
    }
  }

  close() {
    if (!this.internalDisabled) {
      this.internalIsOpen$.next(false);
    }
  }

  enable() {
    this.internalDisabled = false;
  }

  disable() {
    this.internalDisabled = true;
  }

  private getStateValue(isOpen: boolean) {
    return isOpen ? 'open' : 'closed';
  }
}
