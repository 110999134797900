import { CdkTableModule } from '@angular/cdk/table';
import { PageLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/page-loader';
import { NumberFieldModule } from '@nexuzhealth/shared-ui-toolkit/number-field';
import { PartialDateInputModule } from '@nexuzhealth/shared-ui-toolkit/partial-date-input';
import { LoadingSpinnerModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/loading-spinner';
import { EmptyStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/empty-state';
import { LoadingButtonModule } from '@nexuzhealth/shared-ui-toolkit/loading-button';
import { IconLoaderModule } from '@nexuzhealth/shared-ui-toolkit/icon-loader';
import { DatePickerModule } from '@nexuzhealth/shared-ui-toolkit/date-picker';
import { SharedUiToolkitEllipsisActionsModule } from '@nexuzhealth/shared-ui-toolkit/ellipsis-actions';

import { EmailAddressModule } from '@nexuzhealth/shared-ui-toolkit/email-address';
import { BackButtonModule } from '@nexuzhealth/shared-ui-toolkit/back-button';
import { DividerModule } from '@nexuzhealth/shared-ui-toolkit/divider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AkitaNgDevtools, DEVTOOLS_OPTIONS } from '@datorama/akita-ngdevtools';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { EnvironmentVariablesService } from '@nexuzhealth/shared-domain';
import { PractitionerApiService } from '@nexuzhealth/shared/practitioner/data-access';
import { SharedPractitionerFeatureSearchModule } from '@nexuzhealth/shared/practitioner/feature-search';
import {
  ReferenceResolveService,
  SharedReferenceDataDataAccessModule,
} from '@nexuzhealth/shared-reference-data-data-access';
import { SharedReferenceDataFeatureAddressModule } from '@nexuzhealth/shared/reference-data/feature-address';
import { SharedReferenceDataFeatureReferenceDataModule } from '@nexuzhealth/shared/reference-data/feature-reference-data';
import { SettingsService } from '@nexuzhealth/shared-settings-data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared-settings-feature-flags-data-access';
import { FEATURE_FLAG_DATASOURCE, FEATURE_FLAG_QUERY } from '@nexuzhealth/shared-settings-feature-flags-ui';
import { SharedToolkitFeatureShellModule } from '@nexuzhealth/shared/toolkit/feature-shell';
import { SelectsModule } from '@nexuzhealth/shared-ui-toolkit/selects';
import { TabnavModule } from '@nexuzhealth/shared-ui-toolkit/tabnav';
import { AddRemoveButtonsModule } from '@nexuzhealth/shared-ui-toolkit/add-remove-buttons';
import { AlertModule } from '@nexuzhealth/shared-ui-toolkit/alert';
import { AvatarModule } from '@nexuzhealth/shared-ui-toolkit/avatar';
import { CalendarModule } from '@nexuzhealth/shared-ui-toolkit/calendar';
import { ChartsModule } from '@nexuzhealth/shared-ui-toolkit/charts';
import { CollapseModule } from '@nexuzhealth/shared-ui-toolkit/collapse';
import { CounterModule } from '@nexuzhealth/shared-ui-toolkit/counter';
import { DatepickerModule } from '@nexuzhealth/shared-ui-toolkit/datepicker';
import { CanDeactivateGuard } from '@nexuzhealth/shared-ui-toolkit/dirty-check/feature';
import { FocusModule } from '@nexuzhealth/shared-ui-toolkit/focus';
import { NxhFormsModule } from '@nexuzhealth/shared-ui-toolkit/forms';
import { TinyLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/tiny-loader';
import { PhoneNumberModule } from '@nexuzhealth/shared-ui-toolkit/phone-number';
import { RecurrenceModule } from '@nexuzhealth/shared-ui-toolkit/recurrence';
import { ToggleModule } from '@nexuzhealth/shared-ui-toolkit/toggle';
import { HttpTimeoutInterceptor, I18nextConfigService, preloadAll } from '@nexuzhealth/shared-util';
import { SharedUtilAgendaPlannerModule } from '@nexuzhealth/shared-util-agenda-planner';
import { I18NextModule } from 'angular-i18next';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HighlightModule, provideHighlightOptions } from 'ngx-highlightjs';
import { of } from 'rxjs';
import { DetailModule } from '@nexuzhealth/shared-ui-toolkit/detail';
import { ConfirmModule } from '@nexuzhealth/shared-ui-toolkit/confirm';
import { ValidationModule } from '@nexuzhealth/shared-ui-toolkit/validation';
import { ToggleSwitchModule } from '@nexuzhealth/shared-ui-toolkit/toggle-switch';
import { TypeaheadModule } from '@nexuzhealth/shared-ui-toolkit/typeahead';
import { TimeoutStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/timeout-state';
import { TaskCheckModule } from '@nexuzhealth/shared-ui-toolkit/task-check';
import { TimePickerInputModule } from '@nexuzhealth/shared-ui-toolkit/time-picker-input';
import { TableModule } from '@nexuzhealth/shared-ui-toolkit/table';
import { TableLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/table-loader';
import { SkeletonLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/skeleton-loader';
import { SubmitButtonModule } from '@nexuzhealth/shared-ui-toolkit/submit-button';
import { BoldPipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/bold';
import { FormatPersonNamePipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/format-person-name';
import { CompactFormControlDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/compact-form-control';
import { DisableSubmitOnEnterDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/disable-submit-on-enter-directive';
import { EllipsisDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/ellipsis';
import { HotkeysModule } from '@nexuzhealth/shared-ui-toolkit/hotkeys';
import { LookforwardFocusModule } from '@nexuzhealth/shared-ui-toolkit/lookforward-focus';
import { ScrollspyModule } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { LabelDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/label';
import { PartialDatePipeModule } from '@nexuzhealth/shared-ui-toolkit/l10n/partial-date';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { provideNgxMask } from 'ngx-mask';
/* eslint-disable-next-line no-restricted-imports -- only allowed here to configure date-picker */
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, frLocale, nlLocale } from 'ngx-bootstrap/locale';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { PillModule } from '@nexuzhealth/shared-ui-toolkit/pill';
import { BulletModule } from '@nexuzhealth/shared-ui-toolkit/bullet';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { CustomDatePipeModule } from '@nexuzhealth/shared-ui-toolkit/l10n/custom-date';
import { COOKIE_CONFIG_TOKEN, moaprCookieConfig } from '@nexuzhealth/shared/settings/data-access-cookies';
import { environment } from '../environments/environment';
import { AlertMessageDemoComponent } from './alert-message-demo/alert-message-demo.component';
import { AppComponent } from './app.component';
import { BackButtonDemoComponent } from './back-button-demo/back-button-demo.component';
import { BreadcrumbDemoComponent } from './breadcrumb-demo/breadcrumb-demo.component';
import { BulletListDemoComponent } from './bullet-list-demo/bullet-list-demo.component';
import { CalendarDemoComponent } from './calendar-demo/calendar-demo.component';
import { BloeddrukComponent } from './charts-demo/bloeddruk/bloeddruk.component';
import { ChartsDemoComponent } from './charts-demo/charts-demo.component';
import { PijnschaalComponent } from './charts-demo/pijnschaal/pijnschaal.component';
import { PijnschaalService } from './charts-demo/pijnschaal/pijnschaal.service';
import { WeatherComponent } from './charts-demo/weather/weather.component';
import { WeatherService } from './charts-demo/weather/weather.service';
import { CollapseDemoComponent } from './collapse-demo/collapse-demo.component';
import { ConfirmDemoComponent } from './confirm-demo/confirm-demo.component';
import { CounterBarCardDemoComponent } from './counter-bar-card-demo/counter-bar-card-demo.component';
import { CounterDemoComponent } from './counter-demo/counter-demo.component';
import { GridDemoComponent } from './designkit/grid-demo/grid-demo.component';
import { ColorDetailComponent } from './designkit/theme/color-detail/color-detail.component';
import { ThemeComponent } from './designkit/theme/theme.component';
import { TypographyDemoComponent } from './designkit/typography-demo/typography-demo.component';
import { DetailBubbleDemoContentComponent } from './detail-popover-demo/detail-bubble-demo-content.component';
import { DetailBubbleDemoComponent } from './detail-popover-demo/detail-bubble-demo.component';
import { DisableSubmitOnEnterDemoComponent } from './disable-submit-on-enter-demo/disable-submit-on-enter-demo.component';
import { DividerDemoComponent } from './divider-demo/divider-demo.component';
import { DropdownDemoComponent } from './dropdown-demo/dropdown-demo.component';
import { DummyPageComponent } from './dummy-page/dummy-page.component';
import { EllipsisActionsDemoComponent } from './ellipsis-actions-demo/ellipsis-actions-demo.component';
import { EmptyStateDemoComponent } from './empty-state-demo/empty-state-demo.component';
import { AutosuggestDemoComponent } from './forms/autosuggest-demo/autosuggest-demo.component';
import { DatePickerDemoComponent } from './forms/date-picker-demo/date-picker-demo.component';
import { DirtyCheckDemoComponent } from './forms/dirty-check-demo/dirty-check-demo.component';
import { FocusDemoComponent } from './forms/focus-demo/focus-demo.component';
import { LookforwardFocusDemoComponent } from './forms/lookforward-focus-demo/lookforward-focus-demo.component';
import { AddressFormComponent } from './forms/nested-forms-demo/address-form/address-form.component';
import { AmateurFormComponent } from './forms/nested-forms-demo/amateur-form/amateur-form.component';
import { NestedFormsDemoComponent } from './forms/nested-forms-demo/nested-forms-demo.component';
import { PlayerFormComponent } from './forms/nested-forms-demo/player-form/player-form.component';
import { ProFormComponent } from './forms/nested-forms-demo/pro-form/pro-form.component';
import { PasswordInputDemoComponent } from './forms/password-input-demo/password-input-demo.component';
import { RadioAndCheckboxDemoComponent } from './forms/radio-and-checkbox-demo/radio-and-checkbox-demo.component';
import { MockPractitionerApiService } from './forms/selects-demo/mock-practitioner-api.service';
import { SelectsDemoComponent } from './forms/selects-demo/selects-demo.component';
import { TimepickerDemoComponent } from './forms/timepicker-demo/timepicker-demo.component';
import { UploadDemoComponent } from './forms/upload-demo/upload-demo.component';
import { SettingsDemoComponent } from './guidelines/settings-demo/settings-demo.component';
import { TimeoutsDemoComponent } from './guidelines/timeouts-demo/timeouts-demo.component';
import { HelperClassesDemoComponent } from './helper-classes-demo/helper-classes-demo.component';
import { HotkeysDemoComponent } from './hotkeys-demo/hotkeys-demo.component';
import { IconLoaderDemoComponent } from './icon-loader-demo/icon-loader-demo.component';
import { InputListDemoComponent } from './input-list-demo/input-list-demo.component';
import { ListgroupFormComponent } from './input-list-demo/listgroup-form/listgroup-form.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoadingSpinnerDemoComponent } from './loading-spinner/loading-spinner-demo.component';
import { LoadingStatesDemoComponent } from './loading-states-demo/loading-states-demo.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { EllipsisWithTooltipDemoComponent } from './patterns/ellipsis-with-tooltip-demo/ellipsis-with-tooltip-demo.component';
import { PhoneNumberDemoComponent } from './phone-number-demo/phone-number-demo.component';
import { PipesDemoComponent } from './pipes-demo/pipes-demo.component';
import { PlannerDemoComponent } from './planner-demo/planner-demo.component';
import { ProgressBarDemoComponent } from './progress-bar-demo/progress-bar-demo.component';
import { RecurrenceDemoComponent } from './recurrence-demo/recurrence-demo.component';
import { ScrollspyDemoComponent } from './scrollspy-demo/scrollspy-demo.component';
import { SubmitButtonDemoComponent } from './submit-button-demo/submit-button-demo.component';
import { Tab1Component } from './tabnav-demo/demo-tabs/tab1/tab1.component';
import { Tab2Component } from './tabnav-demo/demo-tabs/tab2/tab2.component';
import { Tab3Component } from './tabnav-demo/demo-tabs/tab3/tab3.component';
import { TabnavDemoComponent } from './tabnav-demo/tabnav-demo.component';
import { TabsDemoComponent } from './tabs-demo/tabs-demo.component';
import { TaskCheckDemoComponent } from './task-check-demo/task-check-demo.component';
import { TimeoutStateDemoComponent } from './timeout-state-demo/timeout-state-demo.component';
import { ToastDemoComponent } from './toast-demo/toast-demo.component';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
// for ngx-datepicker
defineLocale('de', deLocale);
defineLocale('nl', nlLocale);
defineLocale('fr', frLocale);

export function languages() {
  return {
    typescript: () => import('highlight.js/lib/languages/typescript'),
    javascript: () => import('highlight.js/lib/languages/javascript'),
    css: () => import('highlight.js/lib/languages/css'),
    scss: () => import('highlight.js/lib/languages/scss'),
    xml: () => import('highlight.js/lib/languages/xml'),
  };
}

const routes: Routes = [
  {
    path: 'charts',
    component: ChartsDemoComponent,
    children: [
      {
        path: 'pijnschaal',
        component: PijnschaalComponent,
      },
      {
        path: 'bloeddruk',
        component: BloeddrukComponent,
      },
      {
        path: 'weather',
        component: WeatherComponent,
      },
    ],
  },
  {
    path: 'dummy-page',
    component: DummyPageComponent,
  },
  {
    path: 'hotkeys',
    component: HotkeysDemoComponent,
  },
  {
    path: 'dropdown',
    component: DropdownDemoComponent,
  },
  {
    path: 'counter',
    component: CounterDemoComponent,
  },
  {
    path: 'page-layout',
    component: PageLayoutComponent,
  },
  {
    path: 'pipes',
    component: PipesDemoComponent,
  },
  {
    path: 'progress-bar',
    component: ProgressBarDemoComponent,
  },
  {
    path: 'landing-page',
    component: LandingPageComponent,
  },
  {
    path: 'submit-button',
    component: SubmitButtonDemoComponent,
  },
  {
    path: 'phonenumber',
    component: PhoneNumberDemoComponent,
  },
  {
    path: 'timeout-state',
    component: TimeoutStateDemoComponent,
  },
  {
    path: 'empty-state',
    component: EmptyStateDemoComponent,
  },
  {
    path: 'task-check',
    component: TaskCheckDemoComponent,
  },
  {
    path: 'tabs',
    component: TabsDemoComponent,
  },
  {
    path: 'icon-loader',
    component: IconLoaderDemoComponent,
  },
  {
    path: 'toast',
    component: ToastDemoComponent,
  },
  {
    path: 'loading-states',
    component: LoadingStatesDemoComponent,
  },
  {
    path: 'timepicker',
    component: TimepickerDemoComponent,
  },
  {
    path: 'recurrence',
    component: RecurrenceDemoComponent,
  },
  {
    path: 'date-picker',
    component: DatePickerDemoComponent,
  },
  {
    path: 'ellipsis-button',
    component: EllipsisActionsDemoComponent,
  },
  {
    path: 'alert-message',
    component: AlertMessageDemoComponent,
  },
  {
    path: 'tab-nav',
    component: TabnavDemoComponent,
    children: [
      {
        path: 'tab1',
        component: Tab1Component,
      },
      {
        path: 'tab2',
        component: Tab2Component,
      },
      {
        path: 'tab3',
        component: Tab3Component,
      },
    ],
  },
  {
    path: 'focus',
    component: FocusDemoComponent,
  },
  {
    path: 'dirty-check',
    component: DirtyCheckDemoComponent,
  },
  {
    path: 'input-list',
    component: InputListDemoComponent,
  },
  {
    path: 'nested-forms',
    component: NestedFormsDemoComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'radio-checkbox',
    component: RadioAndCheckboxDemoComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'theme',
    component: ThemeComponent,
  },
  {
    path: 'confirm',
    component: ConfirmDemoComponent,
  },
  {
    path: 'typography',
    component: TypographyDemoComponent,
  },
  {
    path: 'divider',
    component: DividerDemoComponent,
  },
  {
    path: 'back-button',
    component: BackButtonDemoComponent,
  },
  {
    path: 'autosuggest',
    component: AutosuggestDemoComponent,
  },
  {
    path: 'lookforward-focus',
    component: LookforwardFocusDemoComponent,
  },
  {
    path: 'scrollspy',
    component: ScrollspyDemoComponent,
  },
  {
    path: 'loading-spinner',
    component: LoadingSpinnerDemoComponent,
  },
  {
    path: 'breadcrumb',
    component: BreadcrumbDemoComponent,
    children: [
      {
        path: ':tab',
        component: BreadcrumbDemoComponent,
      },
    ],
    data: {
      breadcrumb: 'custom_breadcrumb',
    },
  },
  {
    path: 'upload',
    component: UploadDemoComponent,
  },
  {
    path: 'planners',
    component: PlannerDemoComponent,
  },
  {
    path: 'calendar',
    component: CalendarDemoComponent,
  },
  {
    path: 'detail-bubble',
    component: DetailBubbleDemoComponent,
  },
  {
    path: 'selects',
    component: SelectsDemoComponent,
  },
  {
    path: 'settings',
    component: SettingsDemoComponent,
  },
  {
    path: 'grid',
    component: GridDemoComponent,
  },
  {
    path: 'timeouts',
    component: TimeoutsDemoComponent,
  },
  {
    path: 'patterns/ellipsis-with-tooltip',
    component: EllipsisWithTooltipDemoComponent,
  },
  {
    path: 'counter-bar-card',
    component: CounterBarCardDemoComponent,
  },
  {
    path: 'password-input',
    component: PasswordInputDemoComponent,
  },
  {
    path: 'collapse',
    component: CollapseDemoComponent,
  },
  {
    path: 'helper-classes',
    component: HelperClassesDemoComponent,
  },
  {
    path: 'disable-submit-on-enter',
    component: DisableSubmitOnEnterDemoComponent,
  },
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'landing-page',
    resolve: { references: ReferenceResolveService },
  },
];

@NgModule({
  declarations: [
    AppComponent,
    FocusDemoComponent,
    ScrollspyDemoComponent,
    AutosuggestDemoComponent,
    BreadcrumbDemoComponent,
    LookforwardFocusDemoComponent,
    DividerDemoComponent,
    ThemeComponent,
    ColorDetailComponent,
    ConfirmDemoComponent,
    DatePickerDemoComponent,
    AlertMessageDemoComponent,
    TimepickerDemoComponent,
    TypographyDemoComponent,
    LoadingSpinnerDemoComponent,
    ToastDemoComponent,
    TabsDemoComponent,
    UploadDemoComponent,
    TabnavDemoComponent,
    Tab1Component,
    Tab2Component,
    Tab3Component,
    EmptyStateDemoComponent,
    UploadDemoComponent,
    DetailBubbleDemoComponent,
    DetailBubbleDemoContentComponent,
    EllipsisActionsDemoComponent,
    SelectsDemoComponent,
    TimeoutStateDemoComponent,
    SettingsDemoComponent,
    ChartsDemoComponent,
    WeatherComponent,
    PijnschaalComponent,
    BloeddrukComponent,
    SettingsDemoComponent,
    PlannerDemoComponent,
    IconLoaderDemoComponent,
    SubmitButtonDemoComponent,
    LandingPageComponent,
    BackButtonDemoComponent,
    LandingPageComponent,
    NestedFormsDemoComponent,
    PlayerFormComponent,
    ProFormComponent,
    AmateurFormComponent,
    AddressFormComponent,
    LoadingStatesDemoComponent,
    PhoneNumberDemoComponent,
    ProgressBarDemoComponent,
    EllipsisWithTooltipDemoComponent,
    CounterBarCardDemoComponent,
    InputListDemoComponent,
    ListgroupFormComponent,
    DummyPageComponent,
    DirtyCheckDemoComponent,
    PageLayoutComponent,
    TimeoutsDemoComponent,
    TaskCheckDemoComponent,
    DropdownDemoComponent,
    HotkeysDemoComponent,
    PasswordInputDemoComponent,
    CollapseDemoComponent,
    HelperClassesDemoComponent,
    DisableSubmitOnEnterDemoComponent,
    BulletListDemoComponent,
    GridDemoComponent,
    RecurrenceDemoComponent,
    RadioAndCheckboxDemoComponent,
    CalendarDemoComponent,
    CounterDemoComponent,
    PipesDemoComponent,
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    LabelDirectiveModule,
    PageLoaderModule,
    NumberFieldModule,
    PartialDateInputModule,
    LoadingSpinnerModule,
    EmptyStateModule,
    LoadingButtonModule,
    IconLoaderModule,
    DatePickerModule,
    SharedUiToolkitEllipsisActionsModule,

    EmailAddressModule,
    BackButtonModule,
    DividerModule,
    BulletModule,
    AvatarModule,
    AddRemoveButtonsModule,
    TinyLoaderModule,
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
    }),
    HotkeysModule,
    ScrollingModule,
    HttpClientModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    HighlightModule,
    I18NextModule.forRoot(),
    BrowserAnimationsModule,
    SharedUtilAgendaPlannerModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    SharedPractitionerFeatureSearchModule,
    ChartsModule,
    PhoneNumberModule,
    NxhFormsModule,
    FocusModule,
    CollapseModule,
    DatepickerModule,
    DetailModule,
    ConfirmModule,
    ToggleModule,
    SharedToolkitFeatureShellModule,
    RecurrenceModule,
    CalendarModule,
    CounterModule,
    SharedReferenceDataDataAccessModule,
    SharedReferenceDataFeatureAddressModule,
    SharedReferenceDataFeatureReferenceDataModule,
    AlertModule,
    SelectsModule,
    ValidationModule,
    ToggleSwitchModule,
    TypeaheadModule,
    TimeoutStateModule,
    TabnavModule,
    TaskCheckModule,
    TimePickerInputModule,
    TableModule,
    TableLoaderModule,
    SkeletonLoaderModule,
    SubmitButtonModule,
    BoldPipeModule,
    FormatPersonNamePipeModule,
    CompactFormControlDirectiveModule,
    DisableSubmitOnEnterDirectiveModule,
    EllipsisDirectiveModule,
    ScrollspyModule,
    LookforwardFocusModule,
    ScrollspyModule,
    PartialDatePipeModule.forRoot(),
    NgbNavModule,
    NgbProgressbarModule,
    NgbAccordionModule,
    NgbTooltipModule,
    ScrollingModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    IconsModule.forRoot(),
    NgbDropdownModule,
    PillModule,
    ButtonModule,
    CustomDatePipeModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadAll,
      deps: [SettingsService, I18nextConfigService],
    },
    WeatherService,
    PijnschaalService,
    {
      provide: EnvironmentVariablesService,
      useValue: environment,
    },
    provideHighlightOptions({
      fullLibraryLoader: () => import('highlight.js'),
    }),
    {
      provide: LOCALE_ID,
      useFactory: (userPreferencesQuery: UserPreferencesQuery) => {
        return userPreferencesQuery.getPreferredLanguage() || 'nl';
      },
      deps: [UserPreferencesQuery], //some service handling global settings
    },
    {
      provide: DEVTOOLS_OPTIONS,
      useValue: { actionsBlacklist: ['@@INIT'] },
    },
    {
      provide: PractitionerApiService,
      useClass: MockPractitionerApiService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTimeoutInterceptor,
      multi: true,
    },
    {
      provide: AuthService,
      useValue: { isAuthorized: () => false, selectAuthorized: () => of(false) },
    },
    {
      provide: FEATURE_FLAG_QUERY,
      useClass: FeatureFlagQuery,
    },
    {
      provide: FEATURE_FLAG_DATASOURCE,
      useClass: FeatureFlagService,
    },
    { provide: COOKIE_CONFIG_TOKEN, useValue: moaprCookieConfig },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
