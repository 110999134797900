<div *ngIf="!!parent">
  <div [formGroup]="parent">
    <div class="period__row__inputs" formGroupName="timeslot">
      <div *ngIf="showLabel" class="period__label period__label--from">{{ 'timing' | i18next }}</div>
      <nh-control>
        <nxh-time-picker-input
          [useClockTime]="true"
          formControlName="start"
          [class.ng-invalid]="parent.get('timeslot').get('start').invalid"
        ></nxh-time-picker-input>
        <val-errors [label]="'timing' | i18next"></val-errors>
      </nh-control>
    </div>
    <val-errors class="period__error" [control]="parent.get('timeslot')"></val-errors>
  </div>
</div>
