import { CookieConfig } from './cookie.service';

export enum moaprEssentialCookieKeys {
  nh_auth_redirectto = 'nh.auth.redirectTo',
  nh_samv2_outdated_notification = 'nh.samv2.outdated-notification',
  nh_user_theme = 'nh.user.theme',
  nh_auth_user_context_name = 'nh.auth.user-context-name',
  nh_auth_login_target_app = 'nh.auth.login_target-app',
  nh_auth_login_last_message = 'nh.auth.login_last-message',
  nh_multimedia_mimetypes = 'nh.multimedia.mimetypes',
}

export enum moaprFunctionalCookieKeys {
  nh_labinformationpreferred = 'nh.labinformationpreferred',
  nh_ehealth_remind_me_after = 'nh.ehealth.remind-me-after',
  nh_ui_sidebar = 'nh.ui.sidebar',
  side_menu_open = 'side-menu-open',
  nh_medication_product_search_type = 'nh.medication.product-search-type',
  nh_locize_i18nextlang = 'nh.locize.i18nextlang',
  nh_user_preferences = 'nh.user.preferences',
}

export const moaprCookieConfig: CookieConfig = {
  essentialCookies: {
    [moaprEssentialCookieKeys.nh_auth_redirectto]: 'sessionStorage',
    [moaprEssentialCookieKeys.nh_samv2_outdated_notification]: 'sessionStorage',
    [moaprEssentialCookieKeys.nh_user_theme]: 'sessionStorage',
    [moaprEssentialCookieKeys.nh_auth_user_context_name]: 'localStorage',
    [moaprEssentialCookieKeys.nh_auth_login_target_app]: 'sessionStorage',
    [moaprEssentialCookieKeys.nh_auth_login_last_message]: 'sessionStorage',
    [moaprEssentialCookieKeys.nh_multimedia_mimetypes]: 'localStorage',
  },
  functionalCookies: {
    [moaprFunctionalCookieKeys.nh_locize_i18nextlang]: 'localStorage',
    [moaprFunctionalCookieKeys.nh_user_preferences]: 'localStorage',
    [moaprFunctionalCookieKeys.nh_labinformationpreferred]: 'localStorage',
    [moaprFunctionalCookieKeys.nh_ehealth_remind_me_after]: 'localStorage',
    [moaprFunctionalCookieKeys.nh_ui_sidebar]: 'localStorage',
    [moaprFunctionalCookieKeys.side_menu_open]: 'localStorage',
    [moaprFunctionalCookieKeys.nh_medication_product_search_type]: 'localStorage',
  },
  cookieDomain: '.nexuzhealth.pro',
  // TODO add thirdparty cookies in HN-13133
  // TODO add description in EN in HN-13133
};
