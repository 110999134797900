<ng-container [formGroup]="dayGroup">
  <nh-radio-group formControlName="_selectNthDayOrWeekday" class="day-group">
    <div class="nh-row">
      <nh-radio
        class="nh-col-s"
        value="nthDay"
        [label]="'_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-1' | i18next"
      >
      </nh-radio>
      <ng-container formGroupName="nthDay">
        <nh-control class="nh-col-s">
          <nxh-number-field formControlName="nthDay" [min]="1" [max]="31" [roundingPrecision]="0"></nxh-number-field>
          <val-errors [label]="'day' | i18next"></val-errors>
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-2' | i18next }}
        </div>
        <nh-control class="nh-col-s">
          <nxh-number-field formControlName="_period" [min]="1" [roundingPrecision]="0"></nxh-number-field>
          <val-errors [label]="'period' | i18next"></val-errors>
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-3' | i18next }}
        </div>
      </ng-container>
    </div>

    <div class="nh-row">
      <nh-radio
        class="nh-col-s"
        value="weekday"
        [label]="'_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-1' | i18next"
      >
      </nh-radio>
      <ng-container formGroupName="weekday">
        <nh-control class="nh-col-2 nh-col--nopad">
          <ng-select formControlName="nth" [clearable]="false">
            <ng-option [value]="1">{{ 'first' | i18next }}</ng-option>
            <ng-option [value]="2">{{ 'second' | i18next }}</ng-option>
            <ng-option [value]="3">{{ 'third' | i18next }}</ng-option>
            <ng-option [value]="4">{{ 'fourth' | i18next }}</ng-option>
            <ng-option [value]="-1">{{ 'last' | i18next }}</ng-option>
          </ng-select>
        </nh-control>
        <nh-control class="nh-col-3 nh-col--nopad">
          <ng-select formControlName="weekday" [clearable]="false">
            <ng-option *ngFor="let day of dayOptions" [value]="day">
              {{ day | i18next }}
            </ng-option>
          </ng-select>
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-2' | i18next }}
        </div>
        <nh-control class="nh-col-s">
          <nxh-number-field formControlName="_period" [min]="1" [max]="31" [roundingPrecision]="0"></nxh-number-field>
          <val-errors [label]="'period' | i18next"></val-errors>
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-3' | i18next }}
        </div>
      </ng-container>
    </div>

    <div class="nh-row" *ngIf="useDayparts">
      <div class="dayparts-option">
        <nh-radio
          class="nh-col-s"
          value="dayparts"
          [nxhDataTest]="'select-dayparts'"
          [label]="'_resource-planning._recurrence.on-the-following-days' | i18next"
        >
        </nh-radio>
        <div formGroupName="dayparts">
          <nxh-multi-toggle-list
            formControlName="days"
            [nxhDataTest]="'select-days'"
            [options]="daysOfMonth"
            [pills]="true"
            [showCheckMarks]="false"
          ></nxh-multi-toggle-list>
        </div>
      </div>
    </div>

    <nxh-time-periods
      *ngIf="useTimeslots"
      [parent]="dayGroup"
      [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
      [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
    ></nxh-time-periods>

    <nxh-dayparts
      [parent]="daypartsFormGroup"
      [partOptions]="dayparts"
      [useDayPartAllDay]="useDayPartAllDay"
      [validateDayPartOrTime]="validateDayPartOrTime"
      *ngIf="$any(parent.get('month')?.get('dayparts')) as daypartsFormGroup"
    ></nxh-dayparts>
  </nh-radio-group>
</ng-container>
