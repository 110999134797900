import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { CustomDatePipeFormats, DateService } from '@nexuzhealth/shared-util';

@Pipe({
  name: 'nxhDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  private dateService = inject(DateService);

  transform(
    value: Date | string | number,
    format?: CustomDatePipeFormats | string | undefined,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | undefined,
    format?: CustomDatePipeFormats | string | undefined,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | null,
    format?: CustomDatePipeFormats | string | undefined,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: null | undefined,
    format?: CustomDatePipeFormats | string | undefined,
    timezone?: string,
    locale?: string,
  ): null;
  transform(
    value: Date | string | number | null | undefined,
    format: CustomDatePipeFormats | string | undefined,
    timezone?: string,
    locale?: string,
  ): string | null {
    return this.dateService.formatDate(value, format, timezone, locale);
  }
}
