import { ChangeDetectorRef, Directive, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { EntityState, QueryEntity } from '@datorama/akita';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdministrativeGenderReference,
  CityReference,
  CountryReference,
  HealthCareWorkerProfessionReference,
  LanguageReference,
  MediumReference,
  MaritalStatusReference,
  NationalityReference,
  ProfessionReference,
  ReferenceType,
  LANGUAGE_ISO_CODE_SOURCE,
} from '../../shared/reference-types';
import { AdministrativeGenderQuery } from '../../state/administrative-gender.query';
import { CountryQuery } from '../../state/country.query';
import { HealthCareWorkerProfessionQuery } from '../../state/health-care-worker-profession.query';
import { LanguageQuery } from '../../state/language.query';
import { MaritalStatusQuery } from '../../state/maritalstatus.query';
import { NationalityQuery } from '../../state/nationality.query';
import { ProfessionQuery } from '../../state/profession.query';
import { CityQuery } from '../../state';
import { MediumQuery } from '../../state/medium.query';

@Directive({
  selector: '[nxhIllegalBaseResolveReferencePipe]', // otherwise tests seem fails
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
abstract class ResolveReferencePipe<T extends ReferenceType> implements PipeTransform, OnDestroy {
  private value = '';
  private subscription: Subscription | undefined;
  private lastReferenceId = '';
  private lastValue = '';

  constructor(
    protected query: QueryEntity<EntityState<T>, T>,
    protected cd: ChangeDetectorRef,
  ) {}

  transform(referenceId?: string): string {
    if (!referenceId) {
      return '';
    }

    if (!this.subscription || this.lastReferenceId !== referenceId) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.lastReferenceId = referenceId;

      // Keeps value up to date. Triggers a rerender when there is a new value.
      // We cannot use distinctUntilChanges because of the lastValue code down below
      this.subscription = this.query
        .selectEntity(referenceId)
        .pipe(map((v) => this.getValue(v, referenceId)))
        .subscribe((val) => {
          if (val !== this.lastValue) {
            this.value = val;
            this.cd.markForCheck();
          }
          this.lastValue = val;
        });

      const storeValue = this.getValue(this.query.getEntity(referenceId), referenceId);
      this.lastValue = storeValue;
      return storeValue;
    }

    return this.value;
  }

  protected getValue(v: T, referenceId: string) {
    return v?.value ?? referenceId;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

@Pipe({
  name: 'resolveProfession',
  pure: false,
})
export class ResolveProfessionPipe extends ResolveReferencePipe<ProfessionReference> implements PipeTransform {
  constructor(professionQuery: ProfessionQuery, cd: ChangeDetectorRef) {
    super(professionQuery, cd);
  }
}

@Pipe({
  name: 'resolveMaritalStatus',
  pure: false,
})
export class ResolveMaritalStatusPipe extends ResolveReferencePipe<MaritalStatusReference> implements PipeTransform {
  constructor(maritalStatusQuery: MaritalStatusQuery, cd: ChangeDetectorRef) {
    super(maritalStatusQuery, cd);
  }
}

@Pipe({
  name: 'resolveHealthCareWorkerProfession',
  pure: false,
})
export class ResolveHealthCareWorkerProfessionPipe
  extends ResolveReferencePipe<HealthCareWorkerProfessionReference>
  implements PipeTransform
{
  constructor(healthCareWorkerProfessionQuery: HealthCareWorkerProfessionQuery, cd: ChangeDetectorRef) {
    super(healthCareWorkerProfessionQuery, cd);
  }
}

@Pipe({
  name: 'resolveCountry',
  pure: false,
})
export class ResolveCountryPipe extends ResolveReferencePipe<CountryReference> implements PipeTransform {
  constructor(countryQuery: CountryQuery, cd: ChangeDetectorRef) {
    super(countryQuery, cd);
  }
}

@Pipe({
  name: 'resolveCity',
  pure: false,
})
export class ResolveCityPipe extends ResolveReferencePipe<CityReference> implements PipeTransform {
  constructor(cityQuery: CityQuery, cd: ChangeDetectorRef) {
    super(cityQuery, cd);
  }
}

@Pipe({
  name: 'resolveNationality',
  pure: false,
})
export class ResolveNationalityPipe extends ResolveReferencePipe<NationalityReference> implements PipeTransform {
  constructor(nationalityQuery: NationalityQuery, cd: ChangeDetectorRef) {
    super(nationalityQuery, cd);
  }
}

@Pipe({
  name: 'resolveLanguage',
  pure: false,
})
export class ResolveLanguagePipe extends ResolveReferencePipe<LanguageReference> implements PipeTransform {
  constructor(query: LanguageQuery, cd: ChangeDetectorRef) {
    super(query, cd);
  }
}

@Pipe({
  name: 'resolveLanguageAsIsoCode',
  pure: false,
})
export class ResolveLanguageAsIsoCodePipe extends ResolveReferencePipe<LanguageReference> implements PipeTransform {
  constructor(query: LanguageQuery, cd: ChangeDetectorRef) {
    super(query, cd);
  }
  protected getValue(v: LanguageReference, referenceId: string): string {
    return v.languageCodes.find((code) => code.source === LANGUAGE_ISO_CODE_SOURCE)?.value ?? referenceId;
  }
}

@Pipe({
  name: 'resolveAdministrativeGender',
  pure: false,
})
export class ResolveAdministrativeGenderPipe
  extends ResolveReferencePipe<AdministrativeGenderReference>
  implements PipeTransform
{
  constructor(query: AdministrativeGenderQuery, cd: ChangeDetectorRef) {
    super(query, cd);
  }
}

@Pipe({
  name: 'resolveMedium',
  pure: false,
})
export class ResolveMediumPipe extends ResolveReferencePipe<MediumReference> implements PipeTransform {
  constructor(query: MediumQuery, cd: ChangeDetectorRef) {
    super(query, cd);
  }
}
