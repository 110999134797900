import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { EMPTY, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { PopoverConfig, PopoverRef, PopoverService } from '@nexuzhealth/shared-ui-toolkit/popover';
import { TypeaheadConfig, TYPEAHEAD_CONFIG } from './typeahead-config.model';
import { TypeaheadOption } from './typeahead-option.model';
import { TypeaheadPopoverComponent } from './typeahead-popover.component';
import { TypeaheadService } from './typeahead.service';

@Directive({
  selector: '[nxhTypeahead]',
  providers: [TypeaheadService],
})
export class TypeaheadDirective<T> implements OnInit, OnChanges {
  @Input() searchMethod: (term: string) => Observable<TypeaheadOption<T>[]>;

  @Input() disable: boolean;
  @Input() resultTemplate: TemplateRef<any>;
  @Input() optionTemplate: TemplateRef<any>;
  @Input() showNoResult = false;
  @Input() noResultTemplate: TemplateRef<any>;
  @Input() focusTemplate: TemplateRef<any>;
  @Input() origin: ElementRef<any> | HTMLElement;
  @Input() scrollable = false;
  @Input() minSearchTermLength = 0;

  // when the input is not backed by a reactive form, we have to update the input value ourselves
  @Input() updateNativeInput = true;
  @Output() selectOption = new EventEmitter<any>();
  @Output() allResultsOption = new EventEmitter<any>();
  @Output() advancedSearchingOption = new EventEmitter<any>();

  private popoverRef: PopoverRef;

  constructor(
    private host: ElementRef,
    private popper: PopoverService,
    private typeaheadService: TypeaheadService<T>,
  ) {}

  ngOnInit(): void {
    if (this.searchMethod) {
      const input: HTMLInputElement = this.host.nativeElement;
      const focus$ = this.focusTemplate ? fromEvent(input, 'focus').pipe(debounceTime(500)) : EMPTY;
      const result$ = fromEvent(input, 'input').pipe(
        map(() => input.value),
        debounceTime(300),
        switchMap((term) => {
          term = term.trim();
          if (term && term.length >= this.minSearchTermLength) {
            this.typeaheadService.updateState({ options: null, term: term, active: -1, completed: false });
            this.open();
            return this.searchMethod(term).pipe(
              map((options) => {
                return { options, term };
              }),
            );
          }
          this.typeaheadService.updateState({ options: null, term: null, active: -1, completed: false });
          return new Observable<boolean>();
        }),
      );

      merge(result$, focus$).subscribe((resultOrFocus) => {
        const [options, term, focus] = resultOrFocus['options']
          ? [resultOrFocus['options'], resultOrFocus['term'], true]
          : [[], '', true];
        // this.options = options as any;
        if (options && (options.length > 0 || this.noResultTemplate || this.showNoResult)) {
          this.typeaheadService.updateState({ options, term, active: 0, completed: true });
          if (!this.isOpen()) {
            setTimeout(() => {
              this.open();
            });
          }
        } else if (focus && this.focusTemplate) {
          this.typeaheadService.updateState({ options, term, active: 0, completed: true });

          if (!this.isOpen()) {
            setTimeout(() => {
              this.open();
            });
          }
        } else {
          this.typeaheadService.updateState({ active: -1, completed: true });
          if (this.isOpen()) {
            this.close();
          }
        }
      });
    }

    // when someone selects an option by clicking (opposed to enter)
    this.typeaheadService.selected$.pipe(filter(($event) => !!$event)).subscribe(($event) => {
      const display = $event.value['display'];
      if (display && this.updateNativeInput) {
        const input: HTMLInputElement = this.host.nativeElement;
        input.value = display;
      }

      this.selectOption.emit($event);
      this.close();
    });

    // when someone want to see all the results
    this.typeaheadService.allResults$.pipe(filter(($event) => !!$event)).subscribe(($event) => {
      this.allResultsOption.emit($event);
      this.close();
    });

    this.typeaheadService.advancedSearching$.pipe(filter(($event) => !!$event)).subscribe(($event) => {
      this.advancedSearchingOption.emit($event);
      this.close();
    });
  }

  /**
   * @deprecated  Until we find a better "TagInput" component, we keep this option.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.searchMethod) {
      return;
    }

    const options = changes['options'] ? changes['options'].currentValue : null;

    if (options && options.length > 0) {
      this.typeaheadService.updateState({ options });

      if (!this.isOpen()) {
        setTimeout(() => {
          this.open();
        });
      }
    } else {
      if (this.isOpen()) {
        this.close();
      }
    }
  }

  open() {
    if (!this.isOpen() && !this.disable) {
      const origin = this.origin ? this.origin : this.host;
      const width =
        this.origin && this.origin['offsetWidth'] ? this.origin['offsetWidth'] : this.host.nativeElement.offsetWidth;

      const config: TypeaheadConfig = {
        optionTemplateRef: this.optionTemplate,
        customTemplateRef: this.resultTemplate,
        noResultTemplateRef: this.noResultTemplate,
        showNoResultMessage: this.showNoResult,
        focusTemplateRef: this.focusTemplate,
        scrollable: this.scrollable,
      };

      const customTokens = new WeakMap();
      customTokens.set(TYPEAHEAD_CONFIG, config);
      customTokens.set(TypeaheadService, this.typeaheadService);

      const popoverConfig: PopoverConfig = {
        origin,
        component: TypeaheadPopoverComponent,
        width,
        customTokens,
        hasBackdrop: false,
      };

      this.popoverRef = this.popper.open(popoverConfig);

      this.popoverRef.result$.subscribe({
        complete: () => {
          this.typeaheadService.updateState({ active: -1 });
          this.popoverRef = null;
        },
      });
    }

    return;
  }

  close() {
    if (this.isOpen()) {
      this.popoverRef.close();
    }
  }

  @HostListener('keydown', ['$event'])
  onChange(event: KeyboardEvent): void {
    if (!this.isOpen()) {
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.typeaheadService.arrowUp();
      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.typeaheadService.arrowDown();
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      const option = this.typeaheadService.getSelectedOption();
      if (this.updateNativeInput) {
        const input: HTMLInputElement = this.host.nativeElement;
        input.value = option ? option['display'] : '';
      }
      this.selectOption.emit(option);
      this.close();
      return;
    }

    if (event.key === 'Tab') {
      event.preventDefault();

      this.close();
      return;
    }

    if (event.key === 'Escape') {
      event.preventDefault();

      this.close();
      return;
    }
  }

  isOpen() {
    return !!this.popoverRef;
  }
}
