import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { NavItem } from '@nexuzhealth/shared-domain';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'nxh-tabnav',
  templateUrl: './tabnav.component.html',
  styleUrls: ['./tabnav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabnavComponent implements OnInit, AfterContentInit, DoCheck {
  @ViewChildren('rla') routerLinks!: QueryList<RouterLinkActive>;
  @ContentChildren(forwardRef(() => TabComponent)) tabs!: QueryList<TabComponent>;
  @Input() navItems!: NavItem[];
  @Input() hideForRouteRegexes: RegExp[] = [];
  @Input() skipLocationChange = false;
  @HostBinding('class.top-level') @Input() topLevel = false;
  /**
   * Specifies active tab. Typically we would let the RouterLinkActive directive take care of this. But
   * there seems to be some issues with this when dealing with secondary nested routing.
   */
  @Input() activeItem: number | null = null;
  @Output() btnActionEmitter = new EventEmitter<void>();

  hide$: Observable<boolean>;

  private tabActiveCurrent;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  get routerLinkActiveOptions(): RouterLinkActive['routerLinkActiveOptions'] {
    return { exact: false };
  }

  ngAfterContentInit(): void {
    this.tabActiveCurrent = this.tabs.some((tab) => tab.isActive());
  }

  ngDoCheck(): void {
    const tabActiveNext = this.tabs?.some((tab) => tab.isActive());
    if (this.tabActiveCurrent !== tabActiveNext) {
      this.tabActiveCurrent = tabActiveNext;
      this.cdr.markForCheck();
    }
  }

  ngOnInit(): void {
    this.hide$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e) => (<NavigationEnd>e).urlAfterRedirects),
      startWith(this.router.url),
      map((url) => this.hideForRouteRegexes.some((regex) => regex.test(url))),
    );
  }
}
