import { Pipe, PipeTransform } from '@angular/core';
import { TimeGridCalendarEvent } from '../calendar.model';

@Pipe({
  name: 'eventBackgroundFade',
})
export class EventBackgroundFadePipe implements PipeTransform {
  transform(event: TimeGridCalendarEvent, defaultColor: string): string {
    if (event.allDay) {
      return 'none';
    } else {
      return 'linear-gradient(0deg, ' + (event.backgroundColor || defaultColor) + ' 0%, transparent 100%)';
    }
  }
}
