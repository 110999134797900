<div class="bullet-container" *ngIf="status">
  <div
    class="bullet-point bullet--{{ status | lowercase }}"
    [ngbTooltip]="text | i18next"
    [disableTooltip]="!showStatusAsTooltip"
    [openDelay]="700"
    placement="top-left"
  ></div>
  <div class="bullet-text-container" *ngIf="title || showStatusAsText">
    <div class="bullet-text bullet-title" *ngIf="showStatusAsText">
      {{ text | i18next }}
    </div>
    <div class="bullet-text bullet-title" *ngIf="title">
      {{ title }}
    </div>
    <div class="bullet-text bullet-description" *ngIf="description">
      {{ description }}
    </div>
    <div class="bullet-text" *ngIf="bulletDescriptionRef">
      <ng-container [ngTemplateOutlet]="bulletDescriptionRef.template" />
    </div>
  </div>
  <div class="bullet-text">
    <ng-content></ng-content>
  </div>
</div>
