<nxh-recurrence-period [parent]="parent" type="daily"></nxh-recurrence-period>

<nxh-time-periods
  [parent]="dayFormGroup"
  [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
  [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
  *ngIf="useTimeslots"
></nxh-time-periods>

<nxh-dayparts
  [parent]="$any(daypartsFormGroup)"
  [partOptions]="dayparts"
  [useDayPartAllDay]="useDayPartAllDay"
  [validateDayPartOrTime]="validateDayPartOrTime"
  *ngIf="dayFormGroup?.get('dayparts') as daypartsFormGroup"
></nxh-dayparts>
