<div class="card-body">
  <div class="card-body__container">
    <nxh-dl [icon]="'info-circle'">
      <nxh-dl-row
        [key]="'_care._care.visiting-moment-time' | i18next"
        [val]="trip?.dateInterval.from | nxhDate: 'shortDateTime'"
      />
      <nxh-dl-row [key]="'_care._care.care-address' | i18next" [val]="trip?.data?.careAddress | formatAddress" />
    </nxh-dl>
    <hr />
    <nxh-dl [icon]="'triangle-exclamation'">
      <nxh-dl-row
        [key]="'_address.info.safetyInfo' | i18next"
        [val]="trip?.data?.careAddress?.addressInfo?.safetyInfo"
      />
    </nxh-dl>
    <hr />
    <nxh-dl [icon]="'person-walking'">
      <nxh-dl-row
        [key]="'_address.info.accessibility' | i18next"
        [val]="trip?.data?.careAddress?.addressInfo?.accessibilityInfo"
      />
    </nxh-dl>
    <hr />
    <nxh-dl [icon]="'key'">
      <nxh-dl-row [key]="'_address.info.keyInfo' | i18next" [val]="trip?.data?.careAddress?.addressInfo?.keyInfo" />
    </nxh-dl>
    <hr />
    <nxh-dl [icon]="'key'">
      <nxh-dl-row [key]="'_address.info.keybox' | i18next" [val]="" />
      <nxh-dl-row
        [key]="'_merge-tags._agreement-keybox-code.label' | i18next"
        [val]="trip?.data?.careAddress?.addressInfo?.keyBox?.code || '-'"
      />
      <nxh-dl-row
        [key]="'_merge-tags._agreement-keybox-emergency-only.label' | i18next"
        [val]="
          trip?.data?.careAddress?.addressInfo?.keyBox?.name
            ? ((trip?.data?.careAddress?.addressInfo?.keyBox?.emergencyOnly ? 'yes' : 'no') | i18next)
            : '-'
        "
      />
      <nxh-dl-row
        [key]="'_merge-tags._agreement-keybox-extra-info.label' | i18next"
        [val]="trip?.data?.careAddress?.addressInfo?.keyBox?.extraInfo || '-'"
      />
    </nxh-dl>
  </div>
</div>
