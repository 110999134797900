<button
  nxh-button
  buttonType="icon"
  [nxhConfirm]="!disableRemoveConfirm"
  [disableConfirm]="disableRemoveConfirm"
  [confirmText]="removeConfirmText"
  [buttonText]="removeButtonText"
  buttonStatus="danger"
  (confirm)="!disableRemoveConfirm && remove.emit()"
  (click)="disableRemoveConfirm && remove.emit()"
  [ngbTooltip]="removeIconToolTip"
  [disabled]="removeDisabled"
  nxhDataTest="remove"
>
  <fa-icon [icon]="removeIcon || 'trash-alt'" />
</button>
<button
  nxh-button
  buttonType="icon"
  (click)="add.emit()"
  [hidden]="!showAdd"
  [ngbTooltip]="addIconToolTip"
  [disabled]="addDisabled"
  nxhDataTest="add"
>
  <fa-icon icon="plus" />
</button>
