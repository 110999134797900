<div class="card card--modal">
  <div class="card-header">
    <div class="card-header__title">{{ '_pract-search-modal.find-careworker' | i18next }}</div>
    <div class="card-header__controls">
      <button nxh-button buttonType="icon" (click)="close()" tabindex="-1">
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf="form.errors?.invalidData">
      <nxh-alert
        [alertTitle]="'_pract-search-modal.insufficient-data-title' | i18next"
        [description]="'_pract-search-modal.insufficient-data-body' | i18next"
      >
      </nxh-alert>
    </ng-container>

    <form [formGroup]="form" (ngSubmit)="submit()" class="nh-row" nhForm #f="nhForm">
      <nh-control class="nh-col" [label]="'_personal.profession' | i18next">
        <ng-select [items]="professions$ | async" bindLabel="label" bindValue="name" formControlName="professionCode">
        </ng-select>
        <!-- <input > -->
      </nh-control>

      <nh-control class="nh-col" [label]="'name' | i18next">
        <input type="text" formControlName="name" />
        <val-errors></val-errors>
      </nh-control>
      <nh-control class="nh-col" [label]="'given' | i18next">
        <input type="text" formControlName="givenName" />
        <val-errors></val-errors>
      </nh-control>
      <nh-control class="nh-col" [label]="'riziv-number' | i18next">
        <input
          type="text"
          formControlName="nihii"
          mask="0-00000-00-000"
          [showMaskTyped]="true"
          [validation]="false"
          placeHolderCharacter="_"
        />
        <val-errors>
          <ng-template valError="invalid-riziv">
            {{ 'errors-invalid-riziv' | i18next }}
          </ng-template>
        </val-errors>
      </nh-control>

      <div class="nh-col search-button">
        <div class="form-group">
          <nh-submit-button
            class="form-group"
            type="primary"
            icon="search"
            label="_medication.search"
          ></nh-submit-button>
        </div>
      </div>
    </form>

    <div class="card-body__container">
      <table
        class="table table--striped table--clickable healthcare-table"
        cdk-table
        [dataSource]="practitioners$ | async"
        [hidden]="tableLoader.isError$ | async"
      >
        <!-- User name Definition -->
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>{{ '_personal.lastname' | i18next }}</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.FamilyName }}</td>
        </ng-container>

        <ng-container cdkColumnDef="given">
          <th cdk-header-cell *cdkHeaderCellDef>{{ '_personal.firstname' | i18next }}</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.GivenName }}</td>
        </ng-container>

        <ng-container cdkColumnDef="riziv">
          <th cdk-header-cell *cdkHeaderCellDef>{{ 'riziv-number' | i18next }}</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.nihii | formatAssignedId: rizivFormatType }}</td>
        </ng-container>
        <ng-container cdkColumnDef="profession">
          <th cdk-header-cell *cdkHeaderCellDef>{{ 'type' | i18next }}</th>

          <td cdk-cell *cdkCellDef="let row">
            {{ row.professionCodes[0]?.code.split('/')[1] | resolveHealthCareWorkerProfession }}
          </td>
        </ng-container>
        <!-- Header and Row Declarations -->
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr
          cdk-row
          [class.active]="activeId === i"
          (click)="selectRow(row, i)"
          *cdkRowDef="let row; columns: displayedColumns; let i = index"
        ></tr>
      </table>
      <nxh-empty-state icon="file-search" *ngIf="!hasSearched">
        <div class="empty__title">{{ '_pract-search-modal.search-practitioner' | i18next }}</div>
      </nxh-empty-state>

      <nxh-table-loader #tableLoader [emptyStateTemplate]="emptyTableState" [debounceTime]="100"></nxh-table-loader>
    </div>

    <div>
      <nxh-paging [minimized]="true"></nxh-paging>
    </div>
  </div>

  <div class="card-footer">
    <div class="card-footer__controls">
      <button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="close()" tabindex="-1">
        {{ 'cancel' | i18next }}
      </button>
      <button
        class="btn btn-success"
        type="button"
        [nxhButtonLoading]="(busy$$ | async) === true"
        [disabled]="activeId === -1 || (busy$$ | async) === true"
        (click)="confirm()"
      >
        {{ 'confirm' | i18next }}
      </button>
    </div>
  </div>
</div>

<ng-template #emptyTableState>
  <nxh-empty-state>
    <div class="empty__title">{{ '_pract-search-modal.careworker-notfound' | i18next }}</div>
    <!-- Later story -->
    <!-- <div class="empty__description">{{ '_pract-search-modal.careworker-notfound-desc' | i18next }}</div> -->
  </nxh-empty-state>
</ng-template>
