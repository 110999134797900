import { Injectable } from '@angular/core';
import {
  AssignedId,
  ListType,
  PagingResult,
  Practitioner,
  PractitionerContext,
  ResourceName,
  UserWithPractitioner,
  User,
} from '@nexuzhealth/shared-domain';
import { last } from 'lodash-es';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SortOptions } from '@nexuzhealth/shared-util';
import { UserApiService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { PractitionerApiService } from './api/practitioner-api.service';
import { PractitionerQuery } from './state/practitioner.query';
import { PractitionerStore } from './state/practitioner.store';
import { PractitionerSearchCriteriaModel } from './model/practitioner-search-criteria.model';
import { PractitionerCreateInfoModel } from './model/practitioner-create-info.model';
import { PractitionerManagementStore } from './state/practitioner-management.store';
import { HealthcareworkerProfessionsStore } from './state/healthcareworker-professions.store';
import { HealthcareworkerProfessionsQuery } from './state/healthcareworker-professions.query';

@Injectable({
  providedIn: 'root',
})
export class PractitionerService {
  constructor(
    private api: PractitionerApiService,
    private userApi: UserApiService,
    private practitionerStore: PractitionerStore,
    private practitionerQuery: PractitionerQuery,
    private practitionerManagementStore: PractitionerManagementStore,
    private healthcareworkerProfessionsQuery: HealthcareworkerProfessionsQuery,
    private healthcareworkerProfessionsStore: HealthcareworkerProfessionsStore,
  ) {}

  removePractitionerFromStore(name: ResourceName) {
    this.practitionerStore.remove(name);
  }

  getPractitioner(name: ResourceName): Observable<Practitioner> {
    const cachedPractitioner = this.practitionerQuery.getEntity(name);

    if (cachedPractitioner != null) {
      return of(cachedPractitioner);
    }

    return this.api.getPractitioner(name).pipe(
      map(
        (p) =>
          ({
            ...p,
            birthDay: p.birthDate ?? '',
          }) as Practitioner,
      ),
      tap((practitioner) => this.practitionerStore.upsert(name, practitioner)),
    );
  }

  getPractitionerForUpdate(name: ResourceName): Observable<Practitioner> {
    const listUsers$ = this.userApi.listUsers(name, 'DEFAULT');
    const getPractitioner$ = this.api.getPractitioner(name);

    this.practitionerManagementStore.reset();

    return forkJoin([getPractitioner$, listUsers$]).pipe(
      map(([practitioner, users]) => {
        return {
          ...practitioner,
          inssRequired: users && users.length > 0,
        };
      }),
      tap((practitioner) => {
        this.practitionerManagementStore.update((state) => {
          return { ...state, practitioner };
        });
      }),
    );
  }

  addPractitioner(practitioner: Partial<Practitioner>): Observable<Practitioner> {
    return this.api.addPractitioner(practitioner);
  }

  searchPractitionerContexts(
    searchString: string,
    professions: string[] = null,
    limit: number = 20,
  ): Observable<PagingResult<PractitionerContext>> {
    return this.api.searchPractitionerContexts(searchString, professions, limit);
  }

  searchPractitioners(
    searchString: string,
    professions: string[] = null,
    limit: number = 20,
    names: string[],
  ): Observable<PagingResult<Practitioner>> {
    return this.api.searchPractitioners(searchString, professions, limit, names);
  }

  searchPractitionersForCriteria(
    searchCriteria: PractitionerSearchCriteriaModel,
    options: { pageSize: number; token?: string },
    sortOptions: SortOptions<Practitioner>,
  ): Observable<PagingResult<Practitioner>> {
    return this.api.searchPractitionersForCriteria(searchCriteria, options, sortOptions);
  }

  findPractitionerByAssignedIds(assignedIds: AssignedId[]): Observable<Practitioner | null> {
    const searchTuples = assignedIds.map(mapAssignedIdToTuples).filter((value) => Array.isArray(value));

    if (searchTuples.length === 0) {
      return of(null);
    }

    const destroy$ = new Subject<void>();
    return from(searchTuples).pipe(
      mergeMap(([type, value]) => this.api.getPractitionerByAssignedId(type, value).pipe(takeUntil(destroy$))),
      filter((practitioner) => practitioner !== null),
      tap((_) => destroy$.complete()),
      take(1),
      defaultIfEmpty(null),
    );
  }

  loadHealthCareWorkerProfessionsForType(type: ListType = ListType.Addressbook): void {
    const cachedProcessionForListType =
      this.healthcareworkerProfessionsQuery.getHealthCareWorkerProfessionsForListType(type);
    if (!cachedProcessionForListType) {
      this.api.getHealthCareWorkerProfessions(type).subscribe({
        next: (professions) => {
          const mappedProfessions = professions.map((profession) => {
            const newVar = {
              ...profession,
              name: profession.name.replace('healthcareworkerprofessions', 'references'),
            };
            return newVar;
          });
          this.healthcareworkerProfessionsStore.storeProfessionsForListType(type, mappedProfessions);
        },
      });
    }
  }

  findHealthCareProfessional(data: any, pageToken?: string, pageSize?: number) {
    return this.api.findHealthCareProfessional(data, pageToken, pageSize);
  }

  searchUsersWithPractitioners(
    searchString: string,
    entityName?: string,
    iamAccessRoleId?: string,
  ): Observable<UserWithPractitioner[]> {
    return this.api.searchUsersWithPractitioners(searchString, entityName, iamAccessRoleId);
  }

  getPractitionerCreateInformation(nihii: string): Observable<PractitionerCreateInfoModel> {
    return this.api.getPractitionerCreateInformation(nihii);
  }
}

function mapAssignedIdToTuples(id: AssignedId): [string, string] | undefined {
  const assignedIdType = last(id.typeName.split('/'));

  if (assignedIdType === undefined) {
    throw Error('Cannot parse the typename of the assigned id');
  }

  const mapTypenameToKey = new Map([
    ['be-nihii', 'nihii'],
    ['be-inss', 'inss'],
  ]);

  if (!mapTypenameToKey.has(assignedIdType)) {
    return null;
  }

  return [mapTypenameToKey.get(assignedIdType), id.value];
}
