import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Observable, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@Component({
  selector: 'nxh-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements OnInit {
  @Input() iconLeft: IconProp;
  @Input() iconRight: IconProp;
  @Input() defaultChecked: boolean;
  @Output() checked = new EventEmitter<boolean>();
  public transitionDuration$: Observable<string>;

  ngOnInit(): void {
    this.transitionDuration$ = of('0.3s').pipe(delay(300), take(1));
  }
}
