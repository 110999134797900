import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectorRef,
  ComponentRef,
  ContentChild,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { assertTrue } from '@nexuzhealth/shared-util';
import { catchError, fromEvent, Observable, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type ButtonType = 'default' | 'subtle-button' | 'icon' | 'link' | 'link-small' | 'action-panel';
export type ButtonStatus = 'primary' | 'success' | 'danger' | 'warning' | 'neutral';
export type ButtonTextSize = 'default' | 'small';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nxh-button]',
})
export class ButtonDirective implements OnChanges, AfterContentInit, OnDestroy, OnInit {
  @Input() buttonType: ButtonType = 'default';
  @Input() buttonStatus: ButtonStatus = 'primary';
  @Input() fontSize: ButtonTextSize = 'default';
  @HostBinding('type')
  @Input()
  type: 'button' | 'submit' = 'button';
  @Input({ transform: booleanAttribute }) loading = false;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) outline = false;
  @Input() action!: () => Observable<any>;

  @HostBinding('disabled')
  get isDisabled() {
    return this.disabled === true || this.loading === true;
  }

  private iconRef?: ComponentRef<FaIconComponent>;

  @ContentChild(FaIconComponent) projectedIcon?: FaIconComponent;
  private projectedIconIcon?: IconProp;

  @HostBinding('class')
  get classnames() {
    switch (this.buttonType) {
      case 'default':
        return {
          btn: true,
          [`btn-${this.buttonStatus}`]: !this.outline,
          [`btn-outline-${this.buttonStatus}`]: this.outline,
        };
      case 'subtle-button':
        return {
          btn: true,
          [`btn-subtle-${this.buttonStatus}`]: true,
        };
      case 'icon':
        return {
          btn: true,
          'btn-icon': !this.outline,
          [`btn-outline-icon`]: this.outline,
          [`btn-icon-${this.buttonStatus}`]: !this.outline,
          [`btn-outline-icon-${this.buttonStatus}`]: this.outline,
        };
      case 'link':
        return {
          btn: true,
          'btn--small': this.fontSize === 'small',
          'btn-link': true,
          [`link-${this.buttonStatus}`]: true,
        };
      case 'link-small':
        return {
          btn: true,
          'btn--small': this.fontSize === 'small',
          'btn-link': true,
          'btn-link-small': true,
          [`link-${this.buttonStatus}`]: true,
        };
      case 'action-panel':
        return {
          btn: true,
          'btn-action-panel': true,
        };
    }
  }

  constructor(
    private button: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['loading'];

    if (!change || change.isFirstChange()) {
      return;
    }

    if (change.currentValue) {
      this.start();
    } else {
      this.stop();
    }
  }

  ngAfterContentInit(): void {
    this.wrapTextNodesInSpan();
    if (this.loading) {
      this.start();
    }
  }

  ngOnInit(): void {
    if (this.action) {
      fromEvent(this.button.nativeElement, 'click')
        .pipe(
          tap(() => {
            this.loading = true;
            this.start();
            this.cdr.markForCheck();
          }),
          switchMap(() => this.action().pipe(catchError(() => of(null)))),
          tap(() => {
            this.loading = false;
            this.stop();
            this.cdr.markForCheck();
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }

  private start() {
    if (this.projectedIcon) {
      this.projectedIconIcon = this.projectedIcon.icon;
      this.projectedIcon.icon = 'spinner';
      this.projectedIcon.animation = 'spin';
      this.projectedIcon.render();
    } else {
      this.iconRef = this.createIcon();
      this.iconRef.instance.icon = 'spinner';
      this.iconRef.instance.animation = 'spin';
      this.iconRef.instance.render();
    }
  }

  private stop() {
    if (this.projectedIcon) {
      if (this.projectedIconIcon) {
        assertTrue(this.projectedIcon);
        this.projectedIcon.icon = this.projectedIconIcon;
      }
      this.projectedIcon.animation = null;
      this.projectedIcon.render();
    } else if (this.iconRef) {
      this.iconRef.destroy();
      this.iconRef = undefined;
    }
  }

  private createIcon() {
    const iconRef = this.vcr.createComponent(FaIconComponent);
    // icon is always created as first element - if it needs to be at the end, use projection
    this.renderer.insertBefore(
      this.button.nativeElement,
      iconRef.location.nativeElement,
      this.button.nativeElement.firstChild,
    );
    return iconRef;
  }

  // Unfortunately the css rule to capitalize the first char does not work with simple
  // text nodes, so we have wrap the text in a span.
  private wrapTextNodesInSpan() {
    const host = this.button.nativeElement;
    host.childNodes.forEach((childNode) => {
      if (childNode?.nodeType === Node.TEXT_NODE) {
        const wrapper = this.renderer.createElement('span');
        this.renderer.insertBefore(host, wrapper, childNode);
        this.renderer.appendChild(wrapper, childNode);
      }
    });
  }

  ngOnDestroy(): void {
    this.iconRef?.destroy();
  }
}
