import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { I18NextPipe } from 'angular-i18next';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators';
import { filterNilValue } from '@datorama/akita';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PagingService } from './paging.service';

@Component({
  selector: 'nxh-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagingComponent<T> implements OnInit {
  @Input() minimized: boolean;
  @Input() isTiny = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Optional() @Input('pagingService') set _pagingService(pagingService: PagingService<T>) {
    this.pagingService = pagingService;
  }

  pageSizeControl = new FormControl<number | null>(null, { validators: [Validators.min(1), Validators.required] });

  prevDisabled$: Observable<boolean>;
  nextDisabled$: Observable<boolean>;
  label$: Observable<string>;

  constructor(
    @Optional() private pagingService: PagingService<T>,
    private i18next: I18NextPipe,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.pageSizeControl.valueChanges
      .pipe(
        debounceTime(300),
        filterNilValue(),
        filter(() => this.pageSizeControl.valid),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((pageSize) => {
        this.pagingService.setPageSize(pageSize, { triggerLoad: true });
      });

    const paginatorChanges$ = this.pagingService.paginatorChanges$.pipe(shareReplay(1));

    paginatorChanges$.pipe(take(1)).subscribe(({ pageSize }) => {
      this.pageSizeControl.setValue(pageSize, { emitEvent: false });
    });

    this.prevDisabled$ = paginatorChanges$.pipe(map(({ pageNumber }) => pageNumber === 0));

    this.nextDisabled$ = this.pagingService.hasNext$.pipe(map((hasNext) => !hasNext));

    this.label$ = paginatorChanges$.pipe(
      map(({ pageNumber, pageSize, total, actualPageSize }) =>
        this.createLabel(pageNumber, pageSize, total, actualPageSize),
      ),
    );
  }

  // public for testing purposes
  createLabel(pageNumber: number, pageSize: number, total: number, actualPageSize: number) {
    let from = 0;
    let to = 0;
    if (pageNumber !== 0 || actualPageSize !== 0) {
      from = pageNumber * pageSize + 1;
      to = from + actualPageSize - 1;
    }
    return `${from} - ${to}` + (total !== 0 ? ` ${this.i18next.transform('_pager.of')} ${total}` : '');
  }

  prevClicked() {
    if (!this.pagingService.isLoading()) {
      this.pagingService.prev();
    }
  }

  nextClicked() {
    if (!this.pagingService.isLoading()) {
      this.pagingService.next();
    }
  }
}
