import { User } from '..';
import { Coding } from './coding';
import { DateTime, EnumValueName, ResourceName } from './common';
import { Practitioner, PractitionerContext } from './practitioner';

export interface HealthElement {
  name?: string;
  sendVersion?: string;
  type?: HealthElementType; // e.g. 'chronic' or 'acute'
  kind?: HealthElementType;
  template?: HealthElementType;
  lifecycle?: Lifecycle;
  description?: string;
  activeStartTime?: DateTime;
  activeEndTime?: DateTime;
  assessmentTime?: string;
  coding?: Coding[];
  systemManaged?: boolean;
  subcontacts?: number;
  severity?: string;
  confidentiality?: string;
  certainty?: string;
  comment?: string;
  relations?: HealthElementRelation[];
  // view field
  detailsLoaded?: boolean;
  attributes?: any;
  responsiblePractitioner?: Practitioner;
  responsiblePractitionerContext?: PractitionerContext;
  user?: User;
  motivation?: HealthElementMotivation;
  validationStatus?: ValidationStatus;
  validatedByPractitioner?: Practitioner;
  validationReason?: string;
  revisions?: HealthElement[];
}

export interface HealthElementCode {
  name?: string;
  code: Coding;
  mappings: Coding[];
}

export interface HealthElementMotivation {
  type: HealthElementMotivationType;
  notes: string;
  practitionerAttributes?: HealthElementMotivationPractitionerAttributes;
  observationAttributes?: HealthElementMotivationObservationAttributes;
}

interface HealthElementMotivationPractitionerAttributes {
  practitioner: Practitioner;
  time: string;
}

interface HealthElementMotivationObservationAttributes {
  time: string;
}

export enum ValidationStatus {
  VALIDATION_PENDING = 'PENDING',
  VALIDATION_VALIDATED = 'VALIDATED',
  VALIDATION_OVERWRITTEN = 'OVERWRITTEN',
  VALIDATION_REFUSED = 'REFUSED',
  VALIDATION_UNDEFINED = 'VALIDATION_UNDEFINED',
}

export function mapHealthElementCodeToHealthElement(heCode: HealthElementCode): Partial<HealthElement> {
  return {
    description: heCode.code.display,
    // don't pass mappings with free text
    coding: heCode.code?.system ? [{ ...heCode.code, userSelected: true }, ...(heCode.mappings ?? [])] : [],
  };
}

export function mapHealthElementToHealthElementCode(he: Partial<HealthElement>) {
  return (he.coding || []).length > 0 && he.description
    ? {
        code: {
          code: (he.coding || [])[0]?.code,
          display: he.description,
        },
      }
    : he.description
      ? { code: { display: he.description } }
      : null;
}

/**
 * Get the health element type based on the Kind & Template property.
 */
export function mapHealthElementType(he: HealthElement): HealthElement {
  switch (he.kind) {
    case HealthElementType.DIAGNOSIS:
    case HealthElementType.RISK:
      return {
        ...he,
        type: he.template ? he.template : he.kind,
        revisions: (he.revisions || []).map((revision) => mapHealthElementType(revision)),
      };
    default:
      return {
        ...he,
        type: he.kind,
        revisions: (he.revisions || []).map((revision) => mapHealthElementType(revision)),
      };
  }
}

/**
 * Populate the Kind & Template property based on the Type property that doesn't exist anymore in the backend
 */
export function populateHealthElementKindAndTemplate(he: HealthElement): HealthElement {
  switch (he.type) {
    case HealthElementType.DIAGNOSIS:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = undefined;
      break;
    case HealthElementType.ALLERGY:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.ALLERGY;
      break;
    case HealthElementType.INTOLERANCE:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.INTOLERANCE;
      break;
    case HealthElementType.ALLERGY_INTOLERANCE_UNDEFINED:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.ALLERGY_INTOLERANCE_UNDEFINED;
      break;
    case HealthElementType.ADR:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.ADR;
      break;
    case HealthElementType.PRODUCT_ABUSE:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.PRODUCT_ABUSE;
      break;
    case HealthElementType.FUNCTIONAL_LIMITATION:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = HealthElementType.FUNCTIONAL_LIMITATION;
      break;
    case HealthElementType.FAMILY_HISTORY:
      he.kind = HealthElementType.FAMILY_HISTORY;
      he.template = undefined;
      break;
    case HealthElementType.RISK:
      he.kind = HealthElementType.RISK;
      he.template = undefined;
      break;
    case HealthElementType.PROFESSIONAL_RISK:
      he.kind = HealthElementType.RISK;
      he.template = HealthElementType.PROFESSIONAL_RISK;
      break;
    case HealthElementType.SOCIAL_RISK:
      he.kind = HealthElementType.RISK;
      he.template = HealthElementType.SOCIAL_RISK;
      break;
    case HealthElementType.TREATMENT:
      he.kind = HealthElementType.TREATMENT;
      he.template = undefined;
      break;
    default:
      he.kind = HealthElementType.DIAGNOSIS;
      he.template = undefined;
  }

  return he;
}

/**
 * This enum does not list *all* HealthElement types (it's a database enum), but
 * provides all types we need for validation, default values etc...
 */
export enum Lifecycle {
  active = 'ACTIVE',
  antecedent = 'ANTECEDENT',
  absent = 'ABSENT',
  archived = 'ARCHIVED',
}

export enum Confidentiality {
  no_confidentiality = 'NO_CONFIDENTIALITY',
  third_party = 'THIRD_PARTY',
  therapeutic_relation = 'THERAPEUTIC_RELATION',
  care_team = 'CARE_TEAM',
  group_practice = 'GROUP_PRACTICE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  dmg = 'GROUP_PRACTICE',
  author = 'AUTHOR',
}

export interface HealthElementRelation {
  healthElement: HealthElement;
  target?: ResourceName;
  relationType: EnumValueName;
  sendVersion?: string;
}

export interface HealthElementAttributes {
  product?: HealthElementAttributeProduct;
  reactions?: HealthElementAttributeReaction[];
  familyMembers?: HealthElementAttributeFamilyMember;
}

export interface HealthElementAttributeProduct {
  code: string;
  description: string;
  type: string;
}

export interface HealthElementAttributeReaction {
  code: string;
  system: string;
}

export interface HealthElementAttributeFamilyMember {
  members: string[];
}

export enum CodingSystem {
  SNOMED_CT = 'snomed-ct',
  ICPC = 'icpc-2',
  ICD_10 = 'icd-10',
  ICD_9_CM = 'icd-9-cm',
  MEDIDOC = 'medidoc',
  MEDIDOC_UNIT = 'medidoc-unit',
  UCUM = 'ucum',
  LAB_SPECIFIC = 'lab-specific',
  LOINC = 'loinc',
  MOAPR = 'moapr',
  IBUI = 'ibui',
}

export enum SearchFieldCodingSystem {
  ANY = '*',
  Code = 'Code',
  Term = 'Descriptions.Term',
}

// Bundled together to make custom sorting work with indexes of the enum
export enum HealthElementType {
  DIAGNOSIS = 'enums/ehrbase_healthelement_kind/values/diagnosis',
  TREATMENT = 'enums/ehrbase_healthelement_kind/values/treatment',
  ALLERGY = 'enums/ehrbase_healthelement_template/values/allergy',
  INTOLERANCE = 'enums/ehrbase_healthelement_template/values/intolerance',
  ALLERGY_INTOLERANCE_UNDEFINED = 'enums/ehrbase_healthelement_template/values/allergy_intolerance_undefined',
  ADR = 'enums/ehrbase_healthelement_template/values/adr',
  FAMILY_HISTORY = 'enums/ehrbase_healthelement_kind/values/family_history',
  PROFESSIONAL_RISK = 'enums/ehrbase_healthelement_template/values/professional_risk',
  PRODUCT_ABUSE = 'enums/ehrbase_healthelement_template/values/product_abuse',
  SOCIAL_RISK = 'enums/ehrbase_healthelement_template/values/social_risk',
  FUNCTIONAL_LIMITATION = 'enums/ehrbase_healthelement_template/values/functional_limitation',
  RISK = 'enums/ehrbase_healthelement_kind/values/risk',
}

export const allergyIntoleranceTypes = [
  HealthElementType.ALLERGY_INTOLERANCE_UNDEFINED,
  HealthElementType.ALLERGY,
  HealthElementType.INTOLERANCE,
];

export enum HealthElementBundlesType {
  DIAGNOSIS = 'enums/ehrbase_healthelement_kind/values/diagnosis',
  TREATMENT = 'enums/ehrbase_healthelement_kind/values/treatment',
  ALLERGY_INTOLERANCE = 'enums/ehrbase_healthelement_template/values/allergy_intolerance',
  ADR = 'enums/ehrbase_healthelement_template/values/adr',
  FAMILY_HISTORY = 'enums/ehrbase_healthelement_kind/values/family_history',
  PROFESSIONAL_RISK = 'enums/ehrbase_healthelement_template/values/professional_risk',
  PRODUCT_ABUSE = 'enums/ehrbase_healthelement_template/values/product_abuse',
  RISK = 'enums/ehrbase_healthelement_kind/values/risk',
  SOCIAL_RISK = 'enums/ehrbase_healthelement_template/values/social_risk',
  FUNCTIONAL_LIMITATION = 'enums/ehrbase_healthelement_template/values/functional_limitation',
}

export enum HealthElementMotivationType {
  PATIENT = 'PATIENT',
  PRACTITIONER = 'PRACTITIONER',
  OBSERVATION = 'OBSERVATION',
}

export const HealthElementBundlesTypeTranslation: Record<HealthElementBundlesType, string> = {
  [HealthElementBundlesType.DIAGNOSIS]: 'diagnosis',
  [HealthElementBundlesType.TREATMENT]: 'treatment',
  [HealthElementBundlesType.ALLERGY_INTOLERANCE]: 'allergy_intolerance',
  [HealthElementBundlesType.ADR]: 'adr',
  [HealthElementBundlesType.FAMILY_HISTORY]: 'family_history',
  [HealthElementBundlesType.PROFESSIONAL_RISK]: 'professional_risk',
  [HealthElementBundlesType.PRODUCT_ABUSE]: 'product_abuse',
  [HealthElementBundlesType.RISK]: 'risk',
  [HealthElementBundlesType.SOCIAL_RISK]: 'social_risk',
  [HealthElementBundlesType.FUNCTIONAL_LIMITATION]: 'functional_limitation',
};

export const healthElementEnumKindCategory = 'ehrbase_healthelement_kind';
export const healthElementEnumTemplateCategory = 'ehrbase_healthelement_template';
export const HealthElementFormName = 'HealthElementForm';
export const ReactionRelationType = 'REACTION';
