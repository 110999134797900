<div
  *ngIf="shorthandValue !== undefined"
  [class.badge-counter--compact]="compact"
  class="badge-counter bg-{{ status }}"
>
  <span>{{ shorthandValue }}</span>
</div>
<div class="badge-counter__content">
  <ng-content></ng-content>
</div>
