<ngb-datepicker
  class="mini-calendar"
  #dp
  (dateSelect)="onDateSelection($event)"
  (navigate)="onNavigate($event)"
  [displayMonths]="1"
  [navigation]="'arrows'"
  [dayTemplate]="t"
>
</ngb-datepicker>

<!--seems that focused isn't refreshed when changed programmatically, hence dp.model.focusDate.equals(date)-->
<ng-template #t let-date let-focused="focused" let-currentMonth="currentMonth">
  <nxh-day-view
    (mouseenter)="onMouseEnter(date)"
    (mouseleave)="onMouseLeave()"
    [date]="date"
    [currentMonth]="currentMonth"
    [hoverDate]="hoveredDate"
    [fromDate]="_range?.fromDate"
    [toDate]="_range?.toDate"
    [bullet]="getBullet(date)"
    [focused]="dp.model.focusDate.equals(date)"
    [noHighlightOnFocused]="mode === 'range' || noHighlightOnFocused"
  ></nxh-day-view>
</ng-template>
