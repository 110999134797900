import { Component, EventEmitter, HostListener, Input, OnInit, Optional, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormHelper } from '../../../shared/form-helper.service';
import { FormHelperDirective } from '../form-helper.directive';
import { ConnectFormHelperDirective } from '../connect-form-helper.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-submit-button',
  templateUrl: './submit-form-button.component.html',
  styleUrls: ['./submit-form-button.component.scss'],
})
export class SubmitFormButtonComponent implements OnInit {
  submitting$: Observable<boolean>;
  hasCustomSubmit: boolean;
  @Input() label = 'save';
  @Input() icon: IconProp = 'check';
  @Input() type = 'success';
  @Input() disabled: boolean | null = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter();

  constructor(
    @Optional() private _formHelperDirective: FormHelperDirective | null,
    @Optional() private connectFormHelperDirective: ConnectFormHelperDirective, // @Optional() private modalFooterComponent: ModalFooterComponent
  ) {}

  @Input() set connect(formHelperDirective: FormHelperDirective) {
    this._formHelperDirective = formHelperDirective;
  }

  get formHelperDirective() {
    // Note: order is important here! In the case where both connectFormHelperDirective and _formHelperDirective
    // have a value, we need to pick the one that is closest to this SubmitButton. In general, this is the
    // ConnectFormHelperDirective that is set on the parent ModalFooterComponent.
    return this.connectFormHelperDirective?.formHelperDirective || this._formHelperDirective;
  }

  get formId() {
    return this.formHelperDirective?.formName;
  }

  get helper() {
    return this.formHelperDirective?.helper;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    if (this.disabled) {
      return;
    }

    this.helper.sourceSubmitButton = this;
    if (this.hasCustomSubmit) {
      $event.preventDefault();
      this.helper.formHelperDirective.customSubmitButtonClicked();
      this.submit.emit();
    }
  }

  ngOnInit(): void {
    if (!(this.helper instanceof FormHelper)) {
      throw new Error(
        'nhForm directive is missing. Either embed the nh-submit-button in a form with an nhForm directive, or, ' +
          'for example when the submit-form-button is no descendent of a form, embed it in a nxh-modal-footer with ' +
          'a [connectNxhForm] directive.',
      );
    }

    this.hasCustomSubmit = this.submit.observers?.length > 0;
    this.submitting$ = this.helper.submitting$.pipe(
      filter(() => !this.hasCustomSubmit || this.helper.sourceSubmitButton === this),
    );
  }
}
