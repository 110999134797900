import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActiveElement,
  Align,
  Chart,
  ChartConfiguration,
  ChartData,
  ChartEvent,
  Filler,
  Legend,
  LegendItem,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { verticalLinePlugin, VerticalLinePositionAndColor } from '../vertical-line-plugin/vertical-line-plugin';
import 'chartjs-adapter-date-fns';
import { ChartColor, ChartTooltip, SelectPointEvent } from './..';

// for treeshaking we only load what we need and register this (iso loading everything from 'chart.js/auto'),
// cf. https://www.chartjs.org/docs/latest/getting-started/integration.html#bundlers-webpack-rollup-etc
Chart.register(LineController, PointElement, LineElement, Tooltip, Legend, TimeScale, Filler, verticalLinePlugin);

@Component({
  selector: 'nxh-time-line-chart',
  templateUrl: './time-line-chart.component.html',
  styleUrls: ['./time-line-chart.component.scss'],
})
export class TimeLineChartComponent implements AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() titleUnit: string;
  @Input() legend = false;
  /**
   * Multi-array holding collections of points to be shown as a line in the graph.
   * If datasets is of type TimeData[] then each dataset can also has a label which refers to the name shown in the legend.
   * Undefined labels are not shown in the legend.
   */
  @Input() datasets: TimeData[];
  /**
   * To be displayed tooltips. There is one tooltip per x value so multiple series share the same tooltip
   */
  @Input() tooltips?: ChartTooltip[];
  /**
   * Options for scaling of the axis. This contains axisNames, type of scaling, display, ...
   */
  @Input() scaleOptions?: ScaleOptions;
  /**
   * Specifies the curvature of the line(s)
   */
  @Input() lineTension = 0;
  /**
   * Specifies wheter the lines are stepped
   */
  @Input() lineStepped = false;
  /**
   * Specifies which or how points are selected on hover, cf. https://www.chartjs.org/docs/latest/configuration/interactions.html#modes
   */
  @Input() interactionMode: 'index' | 'point' | 'dataset' | 'nearest' = 'point';
  /**
   * Specifies which points are shown in the tooltip, e.g. show index to combine all values of the same index
   */
  @Input() tooltipMode: 'index' | 'point' | 'dataset' | 'nearest' = 'point';
  /**
   * Specifies whether the tooltip caret should be visible or not. This is handy in combination with tooltipMode 'index',
   * when the tooltip is relevant to multiple points and sits somewhere in between those points.
   */
  @Input() tooltipHideCaret = false;
  @Output() selectPoint = new EventEmitter<SelectPointEvent>();
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private chart: Chart;
  private verticalLines?: VerticalLine[];

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  @Input() set data(data: TimeChartData | null) {
    this.datasets = data?.datasets ?? [];
    this.tooltips = data?.tooltips;
    this.legend = data?.legend;
    this.verticalLines = data?.verticalLines;
  }

  ngAfterViewInit(): void {
    const config: ChartConfiguration<'line'> = {
      type: 'line',
      data: { datasets: [] },
      plugins: [verticalLinePlugin],
      options: {
        elements: { line: { tension: this.lineTension, stepped: this.lineStepped } },
        layout: {
          padding: {
            left: 0,
            right: 5,
            top: 5,
            bottom: 0,
          },
        },
        animation: {
          easing: 'linear',
        },
        aspectRatio: 5,
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: this.scaleOptions?.x?.time ?? {},
            title: this.scaleOptions?.x?.title ?? {
              display: true,
              align: 'center',
            },
            display: this.scaleOptions?.x?.display ?? true,
            grid: this.scaleOptions?.x?.grid ?? { display: false },
            ticks: this.scaleOptions?.x?.ticks ?? { autoSkip: true, align: 'center' },
            suggestedMin: this.scaleOptions?.x?.suggestedMin,
            suggestedMax: this.scaleOptions?.x?.suggestedMax,
            min: this.scaleOptions?.x?.min,
            max: this.scaleOptions?.x?.max,
          },
          y: {
            title: this.scaleOptions?.y?.title ?? {
              display: true,
              align: 'center',
            },
            display: this.scaleOptions?.y?.display ?? true,
            grid: this.scaleOptions?.y?.grid ?? { display: false },
            ticks: this.scaleOptions?.y?.ticks ?? { autoSkip: true },
            suggestedMin: this.scaleOptions?.y?.suggestedMin,
            suggestedMax: this.scaleOptions?.y?.suggestedMax,
            min: this.scaleOptions?.y?.min,
            max: this.scaleOptions?.y?.max,
          },
        },
        onClick: (event: ChartEvent, active: ActiveElement[], chart: Chart) => {
          const points = active.map((el) => ({
            selected: chart.data.datasets[el.datasetIndex][el.index],
            datasetIndex: el.datasetIndex,
            index: el.index,
          }));
          this.selectPoint.emit({ points, srcEvent: event });
        },
        interaction: {
          mode: this.interactionMode,
        },
        plugins: {
          verticalLine: {
            verticalLinePoints: () => {
              let res: VerticalLinePositionAndColor[] = [];
              if (this.verticalLines) {
                res = this.verticalLines.map((d: VerticalLine) => {
                  return {
                    position: d.onDate.getTime(),
                    color: d.color,
                    label: d.label,
                  };
                });
              }
              return res;
            },
          },
          legend: {
            labels: {
              filter(item: LegendItem, data: ChartData): boolean {
                return item.text !== undefined;
              },
              generateLabels(chart: Chart): LegendItem[] {
                // We use this function to add labels of verticalLines as well
                const verticallines = chart.config.options.plugins.verticalLine.verticalLinePoints();
                const dataLines = chart.config.data.datasets;
                const verticalLineLabels = verticallines.map((line, index) => {
                  return {
                    text: line.label,
                    datasetIndex: -index,
                    fontColor: Chart.defaults.color.toString(), // same color as axis
                    fillStyle: line.color,
                    strokeStyle: line.color,
                  };
                });
                const dataLabels = dataLines.map((line, index) => {
                  return {
                    text: line.label,
                    datasetIndex: index,
                    fontColor: Chart.defaults.color.toString(), // same color as axis
                    fillStyle: line.borderColor as string,
                    strokeStyle: line.borderColor as string,
                  };
                });
                return verticalLineLabels.concat(dataLabels);
              },
            },
            display: this.legend,
            position: 'top',
          },
          tooltip: {
            titleFont: {
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              size: 12,
            },
            footerFont: {
              size: 10,
            },
            borderWidth: 0,
            caretPadding: 16,
            mode: this.tooltipMode,
            intersect: true,
            displayColors: true,
            padding: {
              left: 16,
              right: 16,
              top: 16,
              bottom: 16,
            },
            callbacks: {
              title: (tooltipItems: TooltipItem<'line'>[]): string | string[] => {
                return this.tooltips
                  ? convertValue(this.tooltips[tooltipItems[0].dataIndex].title)
                  : tooltipItems[0].label;
              },
              beforeBody: (tooltipItems: TooltipItem<'line'>[]) => {
                return this.tooltips ? this.tooltips[tooltipItems[0].dataIndex].content : '';
              },
              label: (tooltipItem: TooltipItem<'line'>) => {
                //don't show the labels if we have tooltips because we need to hide the icon that is being
                // added automatically before each label
                return this.tooltips ? undefined : ' ' + tooltipItem.formattedValue;
              },
              footer: (tooltipItems: TooltipItem<'line'>[]) => {
                return this.tooltips ? this.tooltips[tooltipItems[0].dataIndex].footer : '';
              },
            },
          },
        },
      },
    };

    if (this.selectPoint.observers?.length > 0) {
      config.options.onHover = function (e) {
        const points = this.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false);
        if (points.length) {
          e.native.target['style'].cursor = 'pointer';
        } else {
          e.native.target['style'].cursor = 'default';
        }
      };
    }

    config.data.datasets = makeChartDataSet(this.datasets);

    if (this.tooltipHideCaret) {
      config.options.plugins.tooltip.caretSize = 0;
    }

    this.chart = new Chart(this.canvas.nativeElement, config);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.datasets || changes.data) && this.chart) {
      this.chart.config.data.datasets = makeChartDataSet(this.datasets);
      this.chart.update();
    }
  }
}

function convertValue(val) {
  if (!isNaN(val)) {
    return formatNumber(val, this.locale);
  }
  return val;
}

function makeChartDataSet(datasets: TimeData[]) {
  return datasets.map((dataset) => {
    const lineOptions: TimeLineOptions = dataset.lineOptions ?? {
      borderWidth: 3,
      fill: false,
      pointRadius: 7,
      pointHitRadius: 8,
      pointBorderWidth: 3,
      pointHoverBorderWidth: 4,
      pointHoverRadius: 8,
      showLine: true,
    };
    return {
      data: dataset.data,
      label: dataset.label,
      borderWidth: lineOptions.borderWidth,
      fill: lineOptions.fill,
      pointRadius: lineOptions.pointRadius,
      pointHitRadius: lineOptions.pointHitRadius,
      pointBorderWidth: lineOptions.pointBorderWidth,
      pointHoverBorderWidth: lineOptions.pointHoverBorderWidth,
      pointHoverRadius: lineOptions.pointHoverRadius,
      showLine: lineOptions.showLine,
      ...dataset.color,
    };
  });
}

export interface TimeChartData {
  datasets: TimeData[];
  tooltips?: ChartTooltip[];
  legend?: boolean;
  verticalLines?: VerticalLine[];
}

export interface VerticalLine {
  onDate: Date;
  color: string;
  label?: string;
}

export interface TimeData {
  data: any[];
  label?: string;
  color: ChartColor;
  lineOptions?: TimeLineOptions;
}

export interface ScaleOptions {
  x?: AxisOptions;
  y?: AxisOptions;
}

export interface TimeLineOptions {
  borderWidth?: number;
  fill?: number | string | { value: number } | 'start' | 'end' | 'origin' | 'stack' | 'shape' | boolean;
  pointRadius?: number;
  pointHitRadius?: number;
  pointBorderWidth?: number;
  pointHoverBorderWidth?: number;
  pointHoverRadius?: number;
  showLine?: boolean;
}

export interface AxisOptions {
  time?: {
    unit?: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
    displayFormats?: {
      millisecond?: string;
      second?: string;
      minute?: string;
      hour?: string;
      day?: string;
      week?: string;
      month?: string;
      quarter?: string;
      year?: string;
    };
    timezone?: string;
  };
  title?: { display: boolean; text: string; align: Align };
  display?: boolean;
  grid?: { display: boolean };
  ticks?: {
    autoSkip?: boolean;
    minRotation?: number;
    maxRotation?: number;
    autoSkipPadding?: number;
  };
  suggestedMin?: number | string;
  suggestedMax?: number | string;
  min?: number | string;
  max?: number | string;
}
