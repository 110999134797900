import { Inject, Injectable, InjectionToken } from '@angular/core';
import { addYears } from 'date-fns';

const FUNCTIONAL_COOKIES = 'functionalCookies';

export type CookieType = 'localStorage' | 'sessionStorage';

export type CookieConfig = {
  essentialCookies: Record<string, CookieType>;
  functionalCookies: Record<string, CookieType>;
  cookieDomain: string;
};

export const COOKIE_CONFIG_TOKEN = new InjectionToken<CookieConfig>('cookie-config');

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(COOKIE_CONFIG_TOKEN) private cookieConfig: CookieConfig) {}

  /**
   * @name CookieService#allowFunctionalCookies
   *
   * @description
   * Allow functional cookies. Sets the FUNCTIONAL_COOKIES cookie to true
   */
  allowFunctionalCookies() {
    // In a following story, the cookie bar will show 'accept' of 'refuse' or something.
    // When that happens, this will need to be changed.
    // Leaving it to 'true' string for now.
    document.cookie = this.buildCookieString(FUNCTIONAL_COOKIES, 'true');
  }

  shouldShowCookieBar(): boolean {
    // In a following story, the cookie bar will show 'accept' of 'refuse' or something.
    // When that happens, this will need to be changed.
    // Leaving it to 'true' string for now.
    return this.readValueOfFunctionalCookiesCookie() !== 'true';
  }

  private functionalCookiesAreAllowed(): boolean {
    return this.readValueOfFunctionalCookiesCookie() === 'true';
  }

  setFunctionalCookie(key: string, value: string): void {
    if (this.functionalCookiesAreAllowed()) {
      const cookieType = this.cookieConfig.functionalCookies[key];
      if (cookieType === 'sessionStorage') {
        sessionStorage.setItem(key, value);
      } else if (cookieType === 'localStorage') {
        localStorage.setItem(key, value);
      } else {
        console.warn(`${key} is not configured in cookieConfig, ignoring`);
      }
    }
  }

  getFunctionalCookie(key: string): string | null {
    const cookieType = this.cookieConfig.functionalCookies[key];
    const itemValue = cookieType === 'sessionStorage' ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (this.functionalCookiesAreAllowed() && itemValue) {
      return itemValue;
    } else {
      return null;
    }
  }

  setEssentialCookie(key: string, value: string): void {
    const cookieType = this.cookieConfig.essentialCookies[key];
    if (cookieType === 'sessionStorage') {
      sessionStorage.setItem(key, value);
    } else if (cookieType === 'localStorage') {
      localStorage.setItem(key, value);
    } else {
      console.warn(`${key} is not configured in cookieConfig, ignoring`);
    }
  }

  removeCookie(key: string): void {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  getEssentialCookie(key: string): string | null {
    const cookieType = this.cookieConfig.essentialCookies[key];
    return cookieType === 'sessionStorage' ? sessionStorage.getItem(key) : localStorage.getItem(key);
  }

  private buildCookieString(name: string, value: string): string {
    const cookiePath = '/';
    const oneYearLater = addYears(new Date(), 1);

    let str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    str += ';path=' + cookiePath;
    str += ';domain=' + this.cookieConfig.cookieDomain;
    str += ';expires=' + oneYearLater.toUTCString();

    return str;
  }

  private readValueOfFunctionalCookiesCookie(): string | null {
    const cookieArray = document.cookie.split('; ');

    for (const cookie of cookieArray) {
      if (cookie.indexOf(FUNCTIONAL_COOKIES) >= 0) {
        return cookie.split('=')[1];
      }
    }
    return null;
  }
}
