<div
  class="day-container"
  [class.start]="isRangeStart$ | async"
  [class.end]="isRangeEnd$ | async"
  [class.between]="isBetween$ | async"
  [class.focused]="focused"
  [class.disabled]="disabled"
  [class.hovered]="isHovered$ | async"
  [class.no-highlight-on-focused]="noHighlightOnFocused"
  [class.today]="showToday && (isToday$ | async)"
  [class.end-hovered]="isBackgroundEndHovered$ | async"
  [class.outside]="isOutside$ | async"
  [class.wontChangeOnNextDateSelection]="wontChangeOnNextDateSelection"
>
  <div class="day-text">{{ (date$$ | async)?.day }}</div>

  <div class="day-status" *ngIf="bullet !== undefined">
    <nxh-bullet
      class="mini-calendar__day__status bullet--small"
      [status]="bullet.status"
      [ngbTooltip]="bullet.tooltip"
    ></nxh-bullet>
  </div>
</div>
<div
  class="range-background"
  *ngIf="showRangeBackground$ | async"
  [class.range--start]="isBackgroundStart$ | async"
  [class.range--end]="(isBackgroundEnd$ | async) || (isBackgroundEndHovered$ | async)"
></div>
