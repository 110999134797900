import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectErrorDirective } from '../../ng-select-error.directive';

@Component({
  selector: 'nxh-no-result-option',
  templateUrl: 'no-result-option.component.html',
  styleUrls: ['no-result-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultOptionComponent {
  constructor(
    @Optional() private ngSelect?: NgSelectComponent,
    @Optional() private ngSelectErrorDirective?: NgSelectErrorDirective,
  ) {}

  get notFoundText() {
    return this.ngSelect?.notFoundText;
  }

  get error() {
    return !!this.ngSelectErrorDirective?.error;
  }

  get errorText() {
    return this.ngSelectErrorDirective?.errorText;
  }

  onReload($event: MouseEvent) {
    $event.preventDefault();
    const hasReloadSubscriptions = this.ngSelectErrorDirective?.reload.observers.length > 0;
    if (hasReloadSubscriptions) {
      this.ngSelectErrorDirective.reload.emit();
      return;
    }
    window.location.reload();
  }
}
