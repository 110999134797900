<h4>simple case</h4>

<button class="btn btn-danger" [nxhConfirm] [confirmText]="'are you sure??'" (confirm)="remove()">remove</button>

<pre><code language="html" [highlight]="simpleCase"></code></pre>

<h4>with busy indicator</h4>

<button
  class="btn btn-danger"
  [nxhConfirm]
  [confirmText]="'are you sure??'"
  [busy]="busy$ | async"
  (confirm)="removeWithBusyIndicator()"
>
  {{ '_therapeutic-relation.remove' | i18next }}
</button>

<pre><code language="html" [highlight]="withBusyIndicator"></code></pre>

<h4>warning confirm</h4>

<button class="btn btn-warning" [nxhConfirmWarning] (confirm)="send()">send</button>

<pre><code language="html" [highlight]="warningConfirm"></code></pre>

<h4>info confirm</h4>

<button class="btn btn-info" [nxhConfirmInfo] (confirm)="execute()">execute</button>

<pre><code language="html" [highlight]="infoConfirm"></code></pre>

<h4>closing all confirm dialogs</h4>

<p>You can close all confirm dialogs (typically there is only 1) by calling <code>ConfirmService.closeAll()</code></p>
.
