import { Component, HostBinding, inject, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DataListService } from './data-list.service';
import { KeySize } from './key-size.model';

@Component({
  selector: 'nxh-dl',
  template: `
    <ng-template #content><ng-content></ng-content></ng-template>

    <ng-container *ngIf="icon">
      <nxh-dl-row>
        <nxh-dl-key [keySize]="'XS'">
          <fa-icon [icon]="icon" />
        </nxh-dl-key>
        <nxh-dl-val>
          <nxh-dl>
            <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
          </nxh-dl>
        </nxh-dl-val>
      </nxh-dl-row>
    </ng-container>

    <ng-container *ngIf="!icon">
      <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
    </ng-container>
  `,
  providers: [DataListService],
})
export class DataListComponent {
  @HostBinding('class.data-list') clazz = true;
  @Input() set keySize(value: KeySize) {
    this.dataListService.keySize = value;
  }
  @Input() icon: IconProp;

  private dataListService = inject(DataListService);
}
