import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus';
import { isInvalidDateMessage, isValidDate, maxDateValidator, minDateValidator } from '@nexuzhealth/shared-util';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const format = 'DD/MM/YYYY';
const format_month = 'MM/YYYY';

@Component({
  selector: 'nxh-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    { provide: FOCUSSABLE, useExisting: forwardRef(() => DatePickerComponent) },
  ],
})
export class DatePickerComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, Validator, Focusable
{
  // CompactFormErrorDirective fields
  @Input() label: string;
  @Input() placeholder;
  @Input() required = false;
  @Input() mode: 'day' | 'month' | 'year' = 'day';
  // datepicker specific fields
  @Input() errorMap: { [errorName: string]: string } = {};
  @Input() catchAllErrorMessage: string;
  @Input() disableValidation = false;
  @Input() disableMinDateValidation = false;
  @Input() disableMaxDateValidation = false;
  @Input() minDate: Date | null;
  @Input() maxDate: Date | null;
  @Input() datesEnabled: Date[];
  @Input() timeMode: 'none' | 'start-of-day' | 'end-of-day' = 'none';
  @Input() adaptivePosition = true;
  @ViewChild('input', { static: true }) elementRef: ElementRef<HTMLInputElement>;
  @ViewChild(BsDatepickerDirective, { static: true }) dp: BsDatepickerDirective;
  @HostBinding('class.no-capitalization') noCapitalization = true;
  onChange: (_: any) => void;
  onTouch: (_?: any) => void;
  onValidatorChange: () => void;
  disabled = false;
  bsConfig = {
    containerClass: 'theme-moapr',
    adaptivePosition: true,
    dateInputFormat: format,
    returnFocusToInput: true,
    customTodayClass: 'today',
  };
  private dateValue: Date;
  // hacky solution to prevent triggering onTouch too soon - we will only trigger
  // it on blur where the blur is not *triggered* by selecting a date in the picker
  private lastAction$ = new Subject<string>();
  private destroy$ = new Subject<void>();
  private validators: ValidatorFn[] = [];
  /**
   * In case we set an invalid date, ngx-datepicker will display "Invalid Date" in the input field. To be able to reset
   * that input value to the (invalid) input the user specified, we keep track of the "last value" the user specified.
   */
  private lastValue = '';
  /**
   * Ngx-datepicker cannot hold invalid dates; the moment a user specifies an invalid date, ngx datepicker converts this
   * to null. This is a problem for invalidDateValidator, as typically this one would rely on NgControl.value (which
   * would be null in case of an invalid date). Hence, with each onDateSelect() callback, we check wheter the given
   * date was valid or not, and store that in this field. The invalidDateValidator will then inspect this field when it
   * is executed.
   */
  private invalidDate = false;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private localeService: BsLocaleService,
    private cdr: ChangeDetectorRef,
    @Optional() private dataTestDirective: DataTestDirective,
  ) {}

  invalidDateValidator = (control: AbstractControl) => {
    return this.invalidDate ? { 'invalid-date': true } : null;
  };

  ngOnInit() {
    const supportedLocalesNoEn = ['fr', 'fr-BE', 'de'];
    const supportedLocalesNl = ['nl', 'nl-BE'];
    const custom = supportedLocalesNoEn.indexOf(this.locale) > -1;
    const customNL = supportedLocalesNl.indexOf(this.locale) > -1;
    const dateFormat = this.mode === 'month' ? format_month : format;
    // each (lazy) module has its own instance of the LocaleService
    if (custom || customNL) this.localeService.use(this.locale.substr(0, 2));
    if (!this.placeholder) {
      this.placeholder = this.placeholder ?? customNL ? (this.mode === 'month' ? 'MM/JJJJ' : 'DD/MM/JJJJ') : dateFormat;
    }
    this.bsConfig = { ...this.bsConfig, dateInputFormat: dateFormat, adaptivePosition: this.adaptivePosition };
    this.setupValidators();
    this.lastAction$
      .pipe(
        debounceTime(100), // if there is a blur as a result of picking a date in the picker, it is 'overwritten' by
        // the 'selectDate' action, this way we prevent emiting an onTouch
        takeUntil(this.destroy$),
      )
      .subscribe((action) => {
        if (action === 'blur') {
          this.onTouch();
          this.cdr.markForCheck();
        }
      });
  }

  ngAfterViewInit() {
    addDataTestAttributes(this.dataTestDirective?.nxhDataTest || 'date-picker-component', {
      element: this.elementRef.nativeElement,
      suffix: '_datepicker',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setupValidators();

    // note this triggers valueChanges to run (onValidatorChange calls updateValueAndValidity)
    // so potentially some poor programming practices can lead to strange effects: make sure
    // [minDate] and [maxDate] do not always return a new instance on change detection!
    // (https://github.com/angular/angular/issues/25749)
    const shouldReevaluateValidator = (..._changes: { change: SimpleChange; disableValidation: boolean }[]) =>
      _changes.some((change) => change.change && !change.change.firstChange && !change.disableValidation);
    if (
      shouldReevaluateValidator(
        { change: changes['minDate'], disableValidation: this.disableValidation || this.disableMinDateValidation },
        { change: changes['maxDate'], disableValidation: this.disableValidation || this.disableMaxDateValidation },
      )
    ) {
      this.onValidatorChange();
    }
  }

  onDateSelect(date) {
    // if onDateSelect is result from writeValue we don't have to continue
    if (isSameDay(this.dateValue, date) || (!this.dateValue && !date)) {
      return;
    }
    this.invalidDate = isInvalidDateMessage(date) || (date && !isValidDate(date));
    this.lastAction$.next('select');
    date = this.setDateToTimeMode(date);
    this.dateValue = date;
    this.onChange(date);
  }

  setLastValue($event) {
    this.lastValue = $event.srcElement.value;
  }

  resetIfInvalid($event) {
    // If a Date is invalid, ngx-boostrap shows a string "Invalid Date" (toString of invalid date) in the input.
    // If that is the case, we reset it to the actual text that was entered
    const value = $event.target.value;
    if (isInvalidDateMessage(value)) {
      // note: causes small flicker
      setTimeout(() => {
        $event.target.value = this.lastValue;
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(inputDate: Date | string) {
    // we cache this value because setting the date will trigger ngxBootstrap to call the onDateSelect() callback
    // and this would unnecessarily call onChange
    this.dateValue = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

    // after each change we should update invalidDate
    this.invalidDate = isInvalidDateMessage(this.dateValue) || (this.dateValue && !isValidDate(this.dateValue));

    // initial writeValue happens before setting of date-format - https://github.com/valor-software/ngx-bootstrap/issues/5095
    setTimeout(() => {
      this.dp.bsValue = this.dateValue;
    });
  }

  validate(control: AbstractControl) {
    if (control.pristine || this.disableValidation === true) {
      return null;
    }
    return this.validators.reduce((result: ValidationErrors | null, validator) => {
      const error = validator(control);
      if (error) {
        result = result || {};
        return { ...result, ...error };
      } else {
        return result;
      }
    }, null);
  }

  setFocus() {
    this.elementRef.nativeElement.focus();
  }

  @HostListener('keydown', ['$event'])
  onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.dp.show();
      event.preventDefault();
    }
  }

  logShown() {
    this.lastAction$.next('show');
  }

  logBlur() {
    this.lastAction$.next('blur');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setDateToTimeMode(date: Date): Date {
    if (!date) {
      return date;
    }
    switch (this.timeMode) {
      case 'none':
        return date;
      case 'start-of-day':
        return startOfDay(date);
      case 'end-of-day':
        return endOfDay(date);
      default:
        return date;
    }
  }

  private setupValidators() {
    this.validators = [];
    this.validators.push(this.invalidDateValidator);
    if (this.minDate && !this.disableMinDateValidation) {
      this.validators.push(minDateValidator(this.minDate));
    }
    if (this.maxDate && !this.disableMaxDateValidation) {
      this.validators.push(maxDateValidator(this.maxDate));
    }
    if (this.required) {
      this.validators.push(Validators.required);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
