import { Directive, Input, OnInit } from '@angular/core';
import { LogService, SortModel, SortOrder } from '@nexuzhealth/shared-util';

@Directive({
  selector: '[nxhSortTable], table[sortModel], nxh-table[sortModel]',
  standalone: true,
})
export class SortTableDirective<T> implements OnInit {
  @Input() sortModel: SortModel<T>;

  constructor(private logger: LogService) {}

  get sortChanges() {
    return this.sortModel.valueChanges();
  }

  ngOnInit(): void {
    if (!this.sortModel) {
      this.logger.error('Table has [nxhSortTable] directive, but no SortModel has been injected.');
    }
  }

  toggleSort(fieldName) {
    let sortOptions = this.sortModel.value;
    sortOptions = {
      ignoreCase: sortOptions.ignoreCase,
      sortBy: fieldName,
      sortByOrder:
        sortOptions && sortOptions.sortBy === fieldName && sortOptions.sortByOrder === SortOrder.asc
          ? SortOrder.desc
          : SortOrder.asc,
    };
    this.sortModel.setValue(sortOptions);
  }

  setSortOrder(fieldName: any, sortOrder: SortOrder) {
    let sortOptions = this.sortModel.value;
    sortOptions = {
      ...sortOptions,
      sortBy: fieldName,
      sortByOrder: sortOrder,
    };
    this.sortModel.setValue(sortOptions);
  }
}
