<input
  #input
  class="form-control"
  autocomplete="off"
  [type]="isHidden ? 'password' : 'text'"
  (input)="valueChanged($event)"
  (blur)="onTouch()"
  [placeholder]="placeholder"
/>
<span class="visible-icon" (click)="isHidden = !isHidden"
  ><fa-icon [icon]="isHidden ? faEye : faEyeSlash"></fa-icon
></span>
