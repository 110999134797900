import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faChevronDown, faChevronUp, faHandshake, faQuestion, faUserLock } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Focusable } from '@nexuzhealth/shared-ui-toolkit/focus';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Confidentiality } from '@nexuzhealth/shared-domain';

@Component({
  selector: 'nxh-confidentiality-select',
  templateUrl: './confidentiality-select.component.html',
  styleUrls: ['./confidentiality-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConfidentialitySelectComponent),
    },
  ],
})
export class ConfidentialitySelectComponent implements Focusable, ControlValueAccessor {
  @ViewChild('toggleButton') button: ElementRef<HTMLButtonElement>;
  @Input() tabindex = -1;
  @Input() placement: PlacementArray = 'top';

  items = [
    {
      value: Confidentiality.therapeutic_relation,
      label: '_enum.therapeutic_relation',
      icon: faHandshake,
    },
    { value: Confidentiality.author, label: '_enum.author', icon: faUserLock },
  ];
  selectedItem;
  isDisabled = false;
  faCircle = faCircle;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faQuestion = faQuestion;

  private onChange;
  private onTouch;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.selectedItem = this.items.find((conf) => conf.value === value);
  }

  selectItem(item) {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      this.onChange(item.value);
    }
    this.onTouch();
  }

  setFocus() {
    this.button.nativeElement.focus();
  }
}
