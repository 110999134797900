<div [ngSwitch]="mode" class="back-button-container">
  <ng-container *ngSwitchCase="'default'">
    <a (click)="$event.stopPropagation(); $event.preventDefault(); goBack()" nxhDataTest="back-button">
      <ng-container *ngTemplateOutlet="backIcon"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'fixed'">
    <ng-container *ngTemplateOutlet="backIcon"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'custom'">
    <a (click)="$event.stopPropagation(); $event.preventDefault(); click.emit()" nxhDataTest="back-button">
      <ng-container *ngTemplateOutlet="backIcon"></ng-container>
    </a>
  </ng-container>
  <div class="ng-content-container">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #backIcon>
  <span [ngbTooltip]="tooltip | i18next" nxhDataTest="back-icon">
    <fa-icon icon="long-arrow-left" class="long-arrow-left"></fa-icon>
  </span>
</ng-template>
