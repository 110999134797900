import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { ValidationErrorsComponent } from 'ngx-valdemort';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INVALIDABLE_CONTROL } from '../../../shared/form-helper.domain';
import { disabledChanges } from '../../../shared/form-helper.utils';
import { RadioGroupService } from './radio-group.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector,@angular-eslint/component-selector
  selector: 'nh-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [RadioGroupService],
})
export class RadioGroupComponent implements OnInit, AfterContentInit, ControlValueAccessor, OnDestroy {
  @Input() required = false;
  @Input() label?: string;
  @Input() direction?: 'row' | 'col' = 'col';
  @Input() noTopMargin = false;
  @Input() noBottomMargin = false;
  // ok to have 'blur' event, because there is no native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<void>();

  @ContentChildren(ValidationErrorsComponent, { descendants: false })
  errorsComponent: QueryList<ValidationErrorsComponent>;

  @HostBinding('class') classes = INVALIDABLE_CONTROL;
  control = new UntypedFormControl();
  disabled$$ = new BehaviorSubject(false);
  private destroy$$ = new Subject<void>();

  onChange: (value: any) => void;
  onTouch: () => void;

  constructor(
    private radioGroupService: RadioGroupService,
    @Self() @Optional() private ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    } else {
      console.error('Radio-group does not have a [formControl]. Programmer error?');
    }
  }

  ngOnInit(): void {
    this.radioGroupService.parent = this.control;
    this.control.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe(this.onChange);

    disabledChanges(this.control)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((disabled) => this.disabled$$.next(disabled));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false });
  }

  ngAfterContentInit(): void {
    if (!(this.errorsComponent?.length > 0)) {
      return;
    }

    const errorsComponent = this.errorsComponent.get(0);

    if (!errorsComponent.label) {
      errorsComponent.label = this.label;
    }
    errorsComponent.control = this.ngControl.control;
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  onRadioButtonBlur() {
    this.onTouch();
    this.blur.next();
  }
}
