<div *ngIf="!!parent">
  <div [formGroup]="parent">
    <div class="period__row__inputs" formGroupName="timeslot">
      <div class="period__label period__label--from">{{ 'from' | i18next }}</div>
      <nh-control>
        <nxh-time-picker-input
          [useClockTime]="true"
          formControlName="start"
          [class.ng-invalid]="
            parent.get('timeslot').get('start').invalid || parent.get('timeslot').hasError('endTimeIsBeforeStartTime')
          "
        ></nxh-time-picker-input>
        <val-errors [label]="'start-time' | i18next"></val-errors>
      </nh-control>
      <div class="period__label period__label--till">{{ 'until' | i18next }}</div>
      <nh-control>
        <nxh-time-picker-input
          [useClockTime]="true"
          formControlName="end"
          [class.ng-invalid]="
            parent.get('timeslot').get('end').invalid || parent.get('timeslot').hasError('endTimeIsBeforeStartDate')
          "
        ></nxh-time-picker-input>
        <val-errors [label]="'end-time' | i18next"></val-errors>
      </nh-control>
    </div>
    <val-errors class="period__error" [control]="parent.get('timeslot')"></val-errors>
  </div>
</div>
