import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { filterNullOrUndefined } from '@nexuzhealth/shared-util';
import { BehaviorSubject, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export const FOCUS_CONFIG = new InjectionToken<FocusConfig>('FocusConfig');

@Injectable({ providedIn: 'root' })
export class FocusService {
  private setFocusQueue = new Subject<unknown>();
  private focusSubj = new BehaviorSubject(null);
  public focus$ = this.focusSubj.pipe(filterNullOrUndefined);

  constructor(@Optional() @Inject(FOCUS_CONFIG) private focusConfig: FocusConfig) {
    focusConfig = focusConfig ?? { enableThrottling: false }; // currently not enabled

    if (focusConfig.enableThrottling) {
      // first one wins!
      this.setFocusQueue.pipe(throttleTime(focusConfig.throttleTime)).subscribe((name) => {
        this.focusSubj.next(name);
      });
    } else {
      this.setFocusQueue.subscribe((name) => {
        this.focusSubj.next(name);
      });
    }
  }

  focus(handle) {
    this.setFocusQueue.next(handle);
  }
}

export interface FocusConfig {
  enableThrottling: boolean;
  throttleTime?: number;
}
