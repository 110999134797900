import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { CookieService } from '@nexuzhealth/shared/settings/data-access-cookies';
import { LogService } from '../logging';
import { Preloadable } from '../preload';
import { I18NextConfig } from './config';
import { appInit, NamespacesConfig } from './i18next';

export const I18NEXT_NAMESPACES = new InjectionToken<NamespacesConfig>('I18nextNamespaces');

@Injectable({
  providedIn: 'root',
})
export class I18nextConfigService implements Preloadable {
  constructor(
    @Inject(I18NEXT_SERVICE) private i18next: ITranslationService,
    private logger: LogService,
    @Optional() @Inject(I18NEXT_NAMESPACES) private namespacesConfig: NamespacesConfig,
    private cookieservice: CookieService,
  ) {}

  /**
   * Because of the i18next dependency, there should be another preloadable before this that returns this.
   */
  async preload(options: { i18next?: I18NextConfig; preferredLanguage?: string }) {
    this.logger.debug(`[I18nextConfigService] setting up i18next`);
    if (typeof options.i18next === 'undefined') {
      this.logger.debug('[I18nextConfigService] No i18next settings are known');
    }
    return appInit(this.i18next, options.i18next, this.cookieservice, options.preferredLanguage, this.namespacesConfig);
  }
}
