import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { CollapseService } from './collapse.service';

@Injectable()
export class ChevronService {
  icon$ = this.collapseService.isOpen$.pipe(map((isOpen) => (isOpen ? 'chevron-up' : 'chevron-down')));

  constructor(private collapseService: CollapseService) {}
}
