import { Component, ContentChild, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToggleListOption, ToggleListOptionDirective } from '../toggle-list-option.directive';

@Component({
  selector: 'nxh-multi-toggle-list',
  templateUrl: './multi-toggle-list.component.html',
  styleUrls: ['./multi-toggle-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiToggleListComponent),
      multi: true,
    },
  ],
})
export class MultiToggleListComponent implements OnInit, ControlValueAccessor {
  @Input() options!: ToggleListOption[];
  @Input() pills = false;
  @Input() showCheckMarks = true;
  @ContentChild(ToggleListOptionDirective) optionDirective!: ToggleListOptionDirective;
  id = `item-${Math.random()}`;

  form = new FormRecord<FormControl<boolean>>({});

  ngOnInit() {
    this.options.forEach((option) => {
      this.form.addControl(String(option.value), new FormControl({ value: false, disabled: option.disabled }));
    });
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(() => fn(this.valueAsList()));
  }

  registerOnTouched(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }

    // as form.disable and form.enable affects all child controls, we have to set the previously disabled controls
    // disabled again
    this.options.forEach((option) => {
      if (option.disabled) {
        this.form.get(String(option.value))?.disable({ emitEvent: false });
      }
    });
  }

  writeValue(options?: string[]): void {
    Object.entries(this.form.controls).forEach(([name, control]) => {
      control.setValue(options && options.indexOf(name) > -1, { emitEvent: false });
    });
  }

  private valueAsList() {
    // getRawValue, some options might be both disabled *and* selected
    return Object.entries(this.form.getRawValue())
      .filter(([, val]) => val)
      .map(([key]) => key);
  }
}
