<h1>Alerts</h1>

<p>In its simplest form, one passes an alertTitle and description:</p>

<nxh-alert
  type="primary"
  alertTitle="akita"
  description=" Akita is a state management pattern, built on top of RxJS."
  #akita
></nxh-alert>

<nxh-alert type="danger" alertTitle="danger" description=" Danger will Robinson!" #akita></nxh-alert>

<pre>
  <code [highlight]="akitaCode" language="html"></code>
</pre>

<p>
  When title or description are a bit more complex (e.g. the contain html), you embed a AlertTitleComponent or
  AlertDescriptionComponent inside the AlertComponent:
</p>

<nxh-alert [type]="'warning'">
  <nxh-alert-title>Ngrx</nxh-alert-title>
  <nxh-alert-description>
    <ul>
      <li>NgRx Store provides reactive state management for Angular apps inspired by Redux.</li>
      <li>
        NgRx Effects gives you a framework for isolating side effects from your components by connecting observables of
        actions to your store.
      </li>
      <li>NgRx Schematics helps you avoid writing common boilerplate and instead focus on building your application</li>
    </ul>
  </nxh-alert-description>
</nxh-alert>

<pre>
  <code [highlight]="ngrx" language="html"></code>
</pre>

<p>When one has more than one alert, you can combine them by nesting AlertItemComponents inside the AlertComponent:</p>

<nxh-alert type="success">
  <nxh-alert-item
    alertTitle="NGXS"
    description="NGXS is a state management pattern + library for Angular. It acts as a single source of truth for your application's state, providing simple rules for predictable state mutations."
  ></nxh-alert-item>

  <nxh-alert-item>
    <nxh-alert-title>elf</nxh-alert-title>
    <nxh-alert-description>A Reactive Store with Magical Powers</nxh-alert-description>
  </nxh-alert-item>

  <!--  of met ngFor -->

  <nxh-alert-item *ngFor="let alert of alerts$ | async">
    <nxh-alert-title>{{ alert.title }}</nxh-alert-title>
    <nxh-alert-description>{{ alert.description }}</nxh-alert-description>
  </nxh-alert-item>
</nxh-alert>

<pre>
  <code [highlight]="other" language="html"></code>
</pre>

<p>To scroll to an alert, one simply calls its scrollTo() method:</p>

<pre>
  <code [highlight]="scroll" language="html"></code>
</pre>

<button class="btn btn-primary" (click)="akita.scrollTo()">scroll to alert</button>
