import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { isEqual } from 'lodash-es';
import { combineLatest, concat, isObservable, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, share, switchMap, tap } from 'rxjs/operators';
import { BreadcrumbConfig, BreadcrumbItemConfig } from './breadcrumb.domain';

// inspired by https://medium.com/@bo.vandersteene/angular-5-breadcrumb-c225fd9df5cf
@Component({
  selector: 'nxh-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
  ) {}

  ngOnInit() {
    // on load we might have missed the router event
    const init$ = of(true);

    const routeEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    );

    this.breadcrumbs$ = concat(init$, routeEvents$).pipe(
      map(() => this.getAllBreadcrumbObservables(this.activatedRoute.root)),
      switchMap((observables) => combineLatest(observables)),
      distinctUntilChanged(compareSnapshots),
      fixUrls(),
    );
  }

  private getAllBreadcrumbObservables(
    route: ActivatedRoute,
    breadcrumbs: Array<Observable<any>> = [],
  ): Observable<{ label: string; path: string; classnames: string }>[] {
    const breadcrumbs$ = this.getBreadcrumbObservable(route);

    // don't show breadcrumbs with empty labels
    const newBreadcrumbs = breadcrumbs$
      ? breadcrumbs$ instanceof Array
        ? [...breadcrumbs, ...breadcrumbs$]
        : [...breadcrumbs, breadcrumbs$]
      : breadcrumbs;

    if (route.firstChild) {
      //If we are not on our current path yet, there will be more children to look after, to build our breadcumb
      return this.getAllBreadcrumbObservables(route.firstChild, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private getBreadcrumbObservable(route: ActivatedRoute) {
    if (!route.routeConfig) {
      return of({ label: this.translate('home'), path: '' });
    }

    if (route.routeConfig.data) {
      const { skipBreadcrumb, breadcrumb, breadcrumbClassnames } = route.routeConfig.data as BreadcrumbConfig;

      if (skipBreadcrumb) {
        return null;
      }

      // from 'breadcrumb route config'
      if (typeof breadcrumb === 'string') {
        return of({
          label: this.translate(breadcrumb),
          path: route.routeConfig.path,
          classnames: breadcrumbClassnames || '',
        });
      }
      if (Array.isArray(breadcrumb)) {
        return breadcrumb.map((item) =>
          of({
            ...item,
            label: this.translate(item.label),
          }),
        );
      }
    }

    // from 'breadcrumb resolvers', e.g. resolve: { breadcrumb: SubcontactBreadcrumbResolveService }
    // if these need to be translated, this should be taken care of in the resolver
    if (route.snapshot.data) {
      const { breadcrumb } = route.snapshot.data as BreadcrumbConfig;
      if (breadcrumb) {
        return breadcrumb as Observable<BreadcrumbItemConfig> | Observable<BreadcrumbItemConfig>[];
      }
    }

    // path variables are not translated
    const isPathVariable = route.routeConfig.path.startsWith(':');
    if (isPathVariable) {
      const pathVariable = route.snapshot.paramMap.get(route.routeConfig.path.substr(1));
      return of({ label: `#${pathVariable}`, path: pathVariable });
    }

    // path names
    const pathLabel = route.routeConfig.path ? this.translate(route.routeConfig.path) : '';
    return of({ label: pathLabel, path: route.routeConfig.path });
  }

  private translate(key) {
    return this.i18Next.t(key);
  }
}

export interface Breadcrumb {
  label: string;
  url: string[];
  classnames: string;
  // workaround for secondary nav problem
  extraUrl?: string[];
}

function fixUrls() {
  return map((snapshots: BreadcrumbItemConfig[]) => {
    return snapshots.reduce((prev, curr) => {
      if (!curr.label) {
        return prev;
      }

      let path = [];
      switch (typeof curr.path) {
        case 'string':
          path = [curr.path];
          break;
        case 'object':
          path = curr.path;
          break;
      }

      const url = prev.length > 0 ? [...prev[prev.length - 1].url, ...path] : [...path];
      const bc = {
        label: curr.label,
        url,
        classnames: curr.classnames || '',
        extraUrl: curr.extraPath ? [...url, curr.extraPath] : undefined,
      };
      return [...prev, bc];
    }, []);
  });
}

function compareSnapshots(prev: BreadcrumbItemConfig[], current: BreadcrumbItemConfig[]) {
  return isEqual(
    prev.map((item) => item.label),
    current.map((item) => item.label),
  );
}
