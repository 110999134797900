import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Range } from '@nexuzhealth/shared-domain';
import { NgbCalendar, NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { jsDateToNgbDate, ngbDateToJsDate } from '../ngb-date-utils';

@Component({
  selector: 'nxh-calendar-button',
  templateUrl: './calendar-button.component.html',
  styleUrls: ['./calendar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarButtonComponent {
  @Input()
  mode: 'single' | 'range' = 'single';

  /**
   * Prevents highlighting cells that are in range when starting a range by clicking a startDate and hovering to an
   * toDate, as this *might* impact performance (all cells need to be rerendered at each hover). This property is
   * only relevant for mode 'range'
   */
  @Input() noHighlightInRangeOnHover = false;

  /**
   * Prevents highlighting the focused cell. This could be handly in the rare case when you want to highlight a
   * 'range' (e.g. of a whole week) on clicking a particular date. Only relevant for mode 'single'.
   */
  @Input() noHighlightOnFocused = false;

  @Output() dateSelection = new EventEmitter<Date>();
  @Output() rangeSelection = new EventEmitter<Range>();

  hoveredDate: NgbDate | null = null;
  _range!: { fromDate: NgbDate; toDate: NgbDate } | null;
  @Input() date: Date;

  constructor(private calendar: NgbCalendar) {}

  @Input() set range(range: Range) {
    this._range = range ? { fromDate: jsDateToNgbDate(range.fromDate), toDate: jsDateToNgbDate(range.toDate) } : null;
  }

  get startDate(): NgbDate {
    return this.date ? jsDateToNgbDate(this.date) : this._range?.fromDate ?? jsDateToNgbDate(new Date());
  }

  onDateSelection(date: NgbDate, datepicker: NgbInputDatepicker) {
    if (this.mode === 'range') {
      this._range = this.getRange(date);
      this.rangeSelection.emit({
        fromDate: ngbDateToJsDate(this._range.fromDate),
        toDate: ngbDateToJsDate(this._range.toDate),
      });
    } else {
      this.dateSelection.emit(ngbDateToJsDate(date));
    }
    datepicker.close();
  }

  onMouseEnter(date: NgbDate) {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = date;
  }

  onMouseLeave() {
    if (this.noHighlightInRangeOnHover && !this.noHighlightInRangeOnHover) this.hoveredDate = null;
  }

  toggleDatepicker(datepicker: NgbInputDatepicker) {
    datepicker.toggle();

    if (datepicker.isOpen()) {
      setTimeout(() => {
        // remove difficult-to-translate title attribute (an only be done w/angular translation)
        document.querySelectorAll('.btn.btn-link.ngb-dp-arrow-btn').forEach((btn) => btn.removeAttribute('title'));
      });
    }
  }

  private getRange(date: NgbDate) {
    const fromDate = this._range?.fromDate;
    const toDate = this._range?.toDate;
    if (!fromDate && !toDate) {
      return { fromDate: date, toDate: toDate };
    } else if (fromDate && !toDate && date.after(fromDate)) {
      return { fromDate: fromDate, toDate: date };
    } else {
      return { fromDate: date, toDate: null };
    }
  }
}
