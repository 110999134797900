import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'nxh-add-remove-buttons',
  templateUrl: './add-remove-buttons.component.html',
  styleUrls: ['./add-remove-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRemoveButtonsComponent {
  @Input() showAdd = false;
  @Input() addIconToolTip?: string;
  @Input() addDisabled = false;

  @Input() removeIcon?: IconProp;
  @Input() removeIconToolTip?: string;
  @Input() removeDisabled = false;
  @Input() disableRemoveConfirm = true;
  @Input() removeConfirmText?: string;
  @Input() removeButtonText?: string;

  @HostBinding('class.reserve-space-for-label') @Input() reserveSpaceForLabel = false;

  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter<number>();
}
