import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverContent } from '@nexuzhealth/shared-ui-toolkit/popover';
import { BubbleAction, BubbleButton, BubbleRef, DetailBubbleService } from './detail-bubble.service';

@Directive({
  selector: '[nxhDetailBubble]',
  exportAs: 'detailBubble',
})
export class DetailBubbleDirective implements OnDestroy {
  @Input() title: string;
  @Input() actions: BubbleAction[];
  @Input() containerClass: string;
  @Input() size?: 'sm' | 'md' | 'lg';
  @Output() action = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();

  public bubbleRef: BubbleRef;
  private destroy$ = new Subject<void>();

  constructor(private bubbleService: DetailBubbleService) {}

  show(origin: ElementRef<any> | HTMLElement | MouseEvent, content: PopoverContent, data?) {
    if (!origin || !content) {
      throw new Error('Tried to open DetailBubbleComponent but no origin or content was given');
    }

    const _origin = this.getOrigin(origin);

    this.bubbleRef = this.bubbleService.show(content, _origin, {
      title: this.title,
      actions: this.actions,
      containerClass: this.containerClass,
      size: this.size,
      data,
    });

    this.bubbleRef.result$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result) {
          this.action.emit(result);
        }
      },
      complete: () => this.closed.emit(),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getOrigin(origin: ElementRef<any> | HTMLElement | MouseEvent) {
    if (origin instanceof MouseEvent) {
      // we return the clicked upon Element - possibly we might have to traverse the parent tree (with 'parentElement')
      // to fetch a more appropriate element (<tr> for example)
      return origin.target as HTMLElement;
    } else {
      return origin as ElementRef<any> | HTMLElement;
    }
  }
}
