import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@nexuzhealth/shared-ui-toolkit/popover';
import { AssignedIdService } from '@nexuzhealth/shared-util';
import { BubbleConfig } from '@nexuzhealth/shared-ui-toolkit/detail';
import { AgendaPlannerTaskViewModel } from '../../shared/models/date.model';

@Component({
  selector: 'nxh-day-planner-bubble',
  templateUrl: './day-planner-bubble.component.html',
  styleUrls: ['./day-planner-bubble.component.scss'],
})
export class DayPlannerBubbleComponent {
  trip: AgendaPlannerTaskViewModel;

  constructor(
    @Inject(POPOVER_DATA) private bubbleConfig: BubbleConfig,
    private assignedIdService: AssignedIdService,
  ) {
    this.trip = this.bubbleConfig.data;
  }
}
