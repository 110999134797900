<div class="table-loading-indicator" *ngIf="isLoading">
  <div class="table-loading-indicator__container">
    <div class="table-loading-indicator__icon">
      <nxh-icon-loader></nxh-icon-loader>
      <div class="loading-text">
        {{ timeoutWarning ? ('_loading-states.warning-timeout' | i18next) : loadingMessage }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="isEmpty && !(isLoading || isError)">
  <ng-container *ngIf="!emptyStateTemplate; else customEmpty">
    <nxh-empty-state [ngClass]="{ 'small-text': smallText }" icon="file-search">
      <div class="empty__title">{{ '_loading-states.no-items-found' | i18next }}</div>
      <div class="empty__description">
        {{ '_loading-states.no-items-found-for-query' | i18next }}
      </div>
    </nxh-empty-state>
  </ng-container>
</div>

<div class="table-loading-error" *ngIf="isError && !isLoading">
  <nxh-timeout-state [ngClass]="{ 'small-text': smallText }" *ngIf="!errorStateTemplate; else customError">
    <div class="timeout__title">{{ 'general-timeout-error-title' | i18next }}</div>
    <div class="timeout__description">{{ 'general-timeout-error-description' | i18next }}.</div>
  </nxh-timeout-state>
</div>

<ng-template #customEmpty>
  <ng-container [ngTemplateOutlet]="emptyStateTemplate!"></ng-container>
</ng-template>

<ng-template #customError>
  <ng-container [ngTemplateOutlet]="errorStateTemplate!"></ng-container>
</ng-template>
