import {
  AfterContentInit,
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

/**
 * Directive that turns a button into a loading button: when the loading attriubute
 * is set to true, a spinning icon appears, and the button is disabled.
 *
 * Should the button already have an icon, that icon is replaced by the spinning
 * icon.
 *
 * @experimental
 */
@Directive({
  selector: '[nxhButtonLoading]',
})
export class ButtonLoadingDirective implements OnChanges, AfterContentInit, AfterViewInit {
  @Input('nxhButtonLoading') loading = false;
  @ContentChild(FaIconComponent) existingIconComponentRef: FaIconComponent;

  private faSpinner = faSpinner;
  private originalIcon: IconProp;
  private dynamicIconComponentRef: ComponentRef<FaIconComponent>;
  private wrapper: HTMLSpanElement;

  constructor(
    private button: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['loading'];

    if (change.isFirstChange()) {
      return;
    }

    if (change.currentValue) {
      this.start();
    } else {
      this.stop();
    }
  }

  ngAfterContentInit(): void {
    if (this.existingIconComponentRef) {
      this.originalIcon = this.existingIconComponentRef.icon;
    }
  }

  ngAfterViewInit(): void {
    if (this.loading) {
      this.start();
    }
  }

  private start() {
    if (this.existingIconComponentRef) {
      this.existingIconComponentRef.icon = this.faSpinner;
      this.existingIconComponentRef.animation = 'spin';
      this.existingIconComponentRef.render();
    } else {
      this.wrapInSpan();
      this.createDynamicIconComponent();
    }
    this.renderer.setProperty(this.button.nativeElement, 'disabled', true);
  }

  private createDynamicIconComponent() {
    const factory = this.cfr.resolveComponentFactory(FaIconComponent);
    this.dynamicIconComponentRef = this.vcr.createComponent(factory);
    this.dynamicIconComponentRef.instance.icon = this.faSpinner;
    this.dynamicIconComponentRef.instance.animation = 'spin';
    this.dynamicIconComponentRef.instance.render();

    // ViewContainerRef.createComponent places the newly created component as a sibling
    // to the host component. So we move it inside the button via Renderer2's insertBefore().
    const host = this.button.nativeElement;
    this.renderer.insertBefore(host, this.dynamicIconComponentRef.location.nativeElement, host.firstChild);
  }

  private stop() {
    if (this.existingIconComponentRef) {
      this.existingIconComponentRef.icon = this.originalIcon;
      this.existingIconComponentRef.animation = null;
      this.existingIconComponentRef.render();
    } else {
      this.dynamicIconComponentRef?.destroy();
      this.unwrapFromSpan();
    }
    this.renderer.setProperty(this.button.nativeElement, 'disabled', false);
  }

  // Unfortunately the css rule to capitalize the first char does not work with simple
  // text nodes, so we have wrap the text in a span.
  private wrapInSpan() {
    const host = this.button.nativeElement;
    const text = host.firstChild;
    if (text.nodeType === Node.TEXT_NODE) {
      this.wrapper = this.renderer.createElement('span');
      this.renderer.appendChild(this.wrapper, text);
      this.renderer.insertBefore(host, this.wrapper, host.firstChild);
    }
  }

  private unwrapFromSpan() {
    if (this.wrapper) {
      const text = this.wrapper.firstChild;
      this.renderer.removeChild(this.wrapper, text);
      const host = this.button.nativeElement;
      if (host.firstElementChild) {
        this.renderer.insertBefore(host, text, host.firstElementChild);
      } else {
        this.renderer.appendChild(host, text);
      }
      this.renderer.removeChild(host, this.wrapper);
      this.wrapper = null;
    }
  }
}
