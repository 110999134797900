<nxh-alert type="warning" alertTitle="This form hasn't been refactored to the new nhForm!"></nxh-alert>

<div class="nh-row">
  <div class="nh-col-3">
    <div class="card mb-2">
      <div class="card-header">
        <div class="card-header__title">players</div>
      </div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item list-group-item-action"
          [class.active]="i === selectedPlayer"
          *ngFor="let player of players; let i = index"
        >
          <button class="btn btn-link" (click)="selectedPlayer = i">
            {{ player.value?.firstName }} {{ player.value?.lastName }}
            <span class="text-danger" *ngIf="!player.valid && !player.disabled">not valid</span>
          </button>
        </li>
      </ul>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <div class="card-header__title">form value</div>
      </div>
      <div class="card-body">
        <pre>{{ form.value | json }}</pre>
      </div>
    </div>

    <button class="btn btn-primary" type="button" (click)="testEdit()">test edit</button>
    <button class="btn btn-primary" type="button" (click)="testError = !testError">test error</button>
  </div>

  <div class="nh-col-9">
    <form [formGroup]="form" (ngSubmit)="submit()" nxhAutoValidate>
      <input
        type="text"
        class="form-control"
        formControlName="teamName"
        required
        [nxhFocus]="true"
        [label]="'team name'"
      />

      <ng-container formArrayName="players">
        <div class="mb-1">
          <div class="nh-col-12">
            <button type="button" class="btn btn-primary" id="addPlayerButton" (click)="addPlayer()">add player</button>
          </div>
        </div>

        <div
          class="card"
          [class.d-none]="selectedPlayer !== i"
          *ngFor="let player of form.controls['players']['controls']; let i = index"
        >
          <div class="card-header">
            <div class="card-header__title">Player</div>
            <div class="card-header__controls">
              <button type="button" class="btn btn-primary remove-player" (click)="removePlayer(i)">
                remove player {{ i }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <nxh-player-form [formGroup]="player"></nxh-player-form>
          </div>
        </div>
      </ng-container>

      <div class="mt-2 align-items-center">
        <div class="nh-col-6">
          <ng-select
            [required]="true"
            formControlName="teamType"
            [placeholder]="'select a team type'"
            [nxhLabel]="'team type'"
          >
            <ng-option [value]="'professional'">professional</ng-option>
            <ng-option [value]="'amateur'">amateur</ng-option>
          </ng-select>
        </div>
      </div>

      <div class="mt-2">
        <div class="nh-col-12">
          <div *ngIf="form.get('professional')">
            <nxh-pro-form [parentFormGroup]="professionalFormGroup"></nxh-pro-form>
          </div>
          <div *ngIf="form.get('amateur')">
            <nxh-amateur-form [parentFormGroup]="amateurFormGroup"></nxh-amateur-form>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <div class="nh-col-12">
          <button type="button" class="btn btn-link" (click)="cancel()">cancel</button>
          <deprecated-submit-button></deprecated-submit-button>
        </div>
      </div>
    </form>
  </div>
</div>
