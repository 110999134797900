<div class="agenda-navigator">
  <div class="agenda-navigator__navigation-container">
    <button
      nxh-button
      buttonType="subtle-button"
      buttonStatus="neutral"
      class="btn--today"
      (click)="currentDate()"
      *ngIf="currentDateButtonVisible"
    >
      {{ currentDateButtonLabel || ('today' | i18next) }}
    </button>
    <button
      class="btn btn-link btn--nav"
      (click)="previousDate()"
      [ngbTooltip]="'previous%interval' | i18next: { interval: selectedDisplayOptionTranslation }"
      tooltipClass="text-nowrap ms-1"
    >
      <fa-icon size="2x" icon="angle-left"></fa-icon>
    </button>
    <button
      class="btn btn-link btn--nav"
      (click)="nextDate()"
      [ngbTooltip]="'next%interval' | i18next: { interval: selectedDisplayOptionTranslation }"
      tooltipClass="text-nowrap ms-1"
    >
      <fa-icon size="2x" icon="angle-right"></fa-icon>
    </button>
    <p class="agenda-navigator__navigation-container__date-title">{{ selectedDateTitle }}</p>
    <nxh-calendar-button
      [mode]="'single'"
      [range]="{ fromDate: selectedDateInterval.from, toDate: selectedDateInterval.to }"
      [noHighlightOnFocused]="true"
      (dateSelection)="onSelectDate($event)"
      *ngIf="calendarVisible"
    ></nxh-calendar-button>
  </div>
  <div class="agenda-navigator__display-options" *ngIf="intervalOptionsVisible">
    <button
      class="agenda-navigator__display-options__btn capitalize"
      [ngClass]="{ active: selectedDisplayOption === 'month' }"
    >
      {{ 'month' | i18next }}
    </button>
    <button
      class="agenda-navigator__display-options__btn capitalize"
      [ngClass]="{ active: selectedDisplayOption === 'week' }"
    >
      {{ 'week' | i18next }}
    </button>
    <button
      class="agenda-navigator__display-options__btn capitalize"
      [ngClass]="{ active: selectedDisplayOption === 'day' }"
    >
      {{ 'day' | i18next }}
    </button>
  </div>
</div>
