import { ChangeDetectionStrategy, Input, Component } from '@angular/core';

import { ChevronService } from '../chevron.service';

@Component({
  selector: 'nxh-collapse-chevron',
  templateUrl: './collapse-chevron.component.html',
  styleUrls: ['./collapse-chevron.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseChevronComponent {
  icon$ = this.chevronService.icon$;

  @Input()
  align: 'left' | 'right' = 'right';

  constructor(private chevronService: ChevronService) {}
}
